import { Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

export const TabWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.border.boxShadow,
  borderRadius: theme.border.radius,
  padding: theme.spacing(5),
}));

export const TabHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const TabDescription = styled(Typography)(({ theme }) => ({
  lineHeight: '1.6',
  marginBottom: theme.spacing(4),
}));

