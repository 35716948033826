import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Appearance from './Appearance';
import { actions } from '../../reducers';
import { actions as applicationsActions } from '../../../../../../services/applications/reducers';


function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
    appId: state.applications.selectedApplication,
    appName: state.applications.applications[state.applications.selectedApplication].name,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    updateConnectConfigRequest, updateAppLogoRequest, deleteAppLogo, clearError,
  } = actions;
  const { updateApplication } = applicationsActions;

  return {
    actions: bindActionCreators(
      {
        updateConnectConfigRequest,
        updateAppLogoRequest,
        deleteAppLogo,
        updateApplication,
        clearError,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Appearance);
