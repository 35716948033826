import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Box, FormControl, MenuItem, Select, Typography, useTheme, styled } from '@mui/material';
import staticText from '../../../../../../../../localization/Application/connect-config';
import { SelectIcon } from '../../../../../../../../components';

const Form = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const PreviewHeader = ({
  countryOptions,
  handleChange,
  selectedCountry,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="h3">{staticText.connectPreview.heading}</Typography>
      <Form>
        <FormControl variant="outlined">
          <Select
            name="country"
            value={selectedCountry}
            id="country-select"
            onChange={handleChange}
            IconComponent={SelectIcon}
            variant="outlined"
            sx={{
              backgroundColor: theme.palette.common.white,
              minWidth: '230px',
              '#country-select': {
                padding: '8px 32px 8px 12px',
              },
            }}
          >
            {countryOptions.map(option => (
              <MenuItem key={option.code} value={option.code}>
                {option.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Form>
    </Box>
  );
};

export default PreviewHeader;

PreviewHeader.propTypes = {
  countryOptions: arrayOf(shape({
    code: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  handleChange: func.isRequired,
  selectedCountry: string.isRequired,
};
