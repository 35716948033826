import { createTheme } from '@mui/material/styles';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  // https://mui.com/customization/default-theme/#explore
  palette: {
    common: {
      black: '#282828',
      white: '#FFFFFF',
    },
    primary: {
      main: '#00819D',
      light: '#D0E8ED',
      neutral: '#EAF2F6',
    },
    secondary: {
      main: '#005668',
    },
    success: {
      light: '#46ffae',
      main: '#2EB077',
      dark: '#207D54',
      transparent: 'rgba(194, 235, 217, 0.20)',
    },
    warning: {
      light: '#ffeab0',
      main: '#fcba03',
    },
    error: {
      main: '#DD3C3C',
      subtitle: '#410002',
      transparent: 'rgba(221, 60, 60, 0.20)',
    },
    info: {
      main: '#00819D',
    },
    text: {
      primary: '#282828',
      disabled: '#B6B6B6',
    },
    background: {
      paper: '#FFFFFF',
    },
    grey: {
      100: '#F2F4F6',
      200: '#E4E7EB',
      500: '#B6B6B6',
      600: '#6D6D6D',
    },
    purple: {
      light: '#B9BED5',
    },
    blueButton: {
      hover: '#bcd5db',
    },
    graphs: {
      main: '#49A4B8',
      background: '#DBE4E8',
    },
    monetization: {
      main: '#F2BD33',
      light: '#FFC965',
    },
    accents: {
      purple: '#D8C2FF',
    },
  },
  typography: {
    fontFamily: '"ibm-plex-sans-regular", "Arial Narrow", sans-serif',
    bold: {
      fontFamily: '"ibm-plex-sans-medium", "Arial Narrow", sans-serif',
      monospace: '"ibm-plex-mono-medium", "Lucida Console", Monaco, monospace',
    },
    monospace: '"ibm-plex-mono-regular", "Lucida Console", Monaco, monospace',
    fontSize: 16,
    h1: {
      fontFamily: '"poppins", "Arial Narrow", sans-serif',
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: '150%',
      letterSpacing: 'normal',
      textTransform: 'capitalize',
    },
    h2: {
      fontFamily: '"ibm-plex-sans-medium", "Arial Narrow", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: '"ibm-plex-mono-medium", "Lucida Console", Monaco, monospace',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'uppercase',
      color: '#6D6D6D',
    },
    h4: {
      fontFamily: '"ibm-plex-sans-medium", "Arial Narrow", sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
      color: '#282828',
    },
    body1: {
      fontFamily: '"ibm-plex-sans-regular", "Arial Narrow", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle2: {
      fontFamily: '"ibm-plex-sans-medium", "Arial Narrow", sans-serif',
      fontSize: '.875rem',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#6D6D6D',
    },
    caption: {
      fontFamily: '"ibm-plex-sans-regular", "Arial Narrow", sans-serif',
      fontSize: '.775rem',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#6D6D6D',
    },
    tableData: {
      fontSize: '.875rem',
      lineHeight: '1.6rem',
    },
    snippet: {
      fontFamily: '"ibm-plex-mono-regular", "Lucida Console", Monaco, monospace',
      fontSize: '.775rem',
      border: '1px solid #E4E7EB',
      borderRadius: '4px',
      padding: '4px',
    },
    technical: {
      fontFamily: '"ibm-plex-sans-regular", "Arial Narrow", sans-serif',
      wordBreak: 'break-all',
    },
    copyable: {
      fontFamily: '"ibm-plex-mono-regular", "Lucida Console", Monaco, monospace',
      border: '1px solid #E4E7EB',
      borderRadius: '4px',
      backgroundColor: '#F2F4F6',
      boxSizing: 'border-box',
      padding: '12px 14px',
      fontSize: '1rem',
    },
    fontSizes: {
      xs: '.75rem',
      sm: '1rem',
      med: '1.5rem',
      lg: '2rem',
      xl: '3rem',
    },
    lineHeights: {
      paragraph: '1.8rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 200,
      md: 700,
      lg: 993,
      xl: 1280,
      iPadPro: 1366,
    },
  },
  border: {
    radius: '4px',
    boxShadow: '0px 0px 4px rgba(142, 142, 142, 0.25)',
    boxShadow2: '0px 0px 4px rgba(142, 142, 142, 0.5)',
    boxShadow3: '0px 4px 4px 0px #E8E8E840',
  },
  width: {
    content: '1152px',
    paragraph: '70ch',
    sidebar: {
      expanded: '220px',
      closed: '65px',
    },
  },
  height: {
    topbar: '65px',
    banner: '50px',
  },
  zIndex: {
    popover: 1000,
    topbar: 100,
    sidebar: 90,
  },
});

// Theme composition: using theme options to define other options
// https://mui.com/customization/theming/#api
theme = createTheme(theme, {
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 2),
          borderBottom: `1px solid ${theme.palette.grey[200]}`,

          '&.MuiPaper-root': {
            boxShadow: 'none',
          },
          '&.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '&:before': {
            opacity: 0,
          },
          '&:last-of-type': {
            borderBottom: '0px',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: 'auto',
            '&.Mui-expanded': {
              minHeight: 'auto',
            },
          },
          '& .MuiAccordionSummary-content': {
            order: 2,
            alignItems: 'center',
            margin: '0px',
            '&.Mui-expanded': {
              margin: '0px',
            },
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: theme.palette.primary.main,
            order: 1,
            padding: '0px',
            '&.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '16px 48px 0px 86px',
            display: 'block',

            '& .MuiTypography-root': {
              marginBottom: theme.spacing(2),
            },
          },
          '& a': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          border: '0',
          boxShadow: 'none',
          lineHeight: '1.5rem',
          fontFamily: theme.typography.bold.fontFamily,
          textTransform: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          boxShadow: 'none',
          transition:
          'color 0.25s ease, background-color 0.25s ease, border 0.25s ease',
          '&:hover, &:focus': {
            boxShadow: 'none',
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
          },
        },
        containedPrimary: {
          '@media (hover:none)': {
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: theme.palette.secondary.main,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
            opacity: '0.5',
          },
        },
        containedSecondary: {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[100],

          '&:hover': {
            color: theme.palette.grey[600],
            backgroundColor: theme.palette.grey[200],
            boxShadow: 'none',
          },
        },
        containedError: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.error.main,

          '&:hover': {
            backgroundColor: theme.palette.error.dark,
            boxShadow: 'none',
          },
        },
        outlined: {
          backgroundColor: theme.palette.background.paper,
          '&:hover, &:focus': {
            boxShadow: 'none',
            backgroundColor: theme.palette.background.paper,
          },

          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.grey[200]}`,
          },
        },
        outlinedPrimary: {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.main}`,
          transition: 'color 0.25s ease, background-color 0.25s ease, border 0.25s ease',
          '&:hover, &:focus': {
            color: `${theme.palette.secondary.main}`,
            border: `1px solid ${theme.palette.secondary.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.background.paper,
          },
        },
        outlinedError: {
          border: `1px solid ${theme.palette.error.main} !important`,

          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.grey[200]} !important`,
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            textDecoration: 'underline',
          },
        },
        sizeLarge: {
          padding: '13px 64px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          paddingBottom: '16px',
          color: theme.palette.text.primary,
          fontFamily: theme.typography.bold.fontFamily,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        menu: {
          backgroundColor: theme.palette.background.paper,
        },
        panel: {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
          },

          '&:hover:before': {
            borderBottom: `1px solid ${theme.palette.grey[500]} !important`,
          },

          '&:after': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingBottom: '16px',
          color: theme.palette.text.primary,
          fontFamily: theme.typography.bold.fontFamily,
        },
        outlined: {
          paddingBottom: '16px',
          color: theme.palette.grey[600],
          fontFamily: '"ibm-plex-sans-regular", "Arial Narrow", sans-serif',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },

          '&.Mui-disabled svg': {
            color: theme.palette.grey[500],
          },

          '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },

          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.error.main} !important`,
          },
        },
        input: {
          padding: '16px',
        },
        notchedOutline: {
          borderColor: theme.palette.grey[500],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover, &:focus': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:first-child': {
            paddingTop: '0px',
          },
          '&:last-child': {
            paddingBottom: '0px',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: '0px',
          marginBottom: '0px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '16px 0px',
          margin: '0px 24px',
          fontFamily: theme.typography.bold.fontFamily,
          fontSize: '1.125rem',
          textTransform: 'none',
          lineHeight: 'normal',
          minHeight: 'auto',
          minWidth: 'auto',
          '@media (min-width: 0px)': {
            minWidth: 'auto',
          },
          '&$selected': {
            color: theme.palette.text.primary,
          },
        },
        textColorInherit: {
          color: theme.palette.grey[600],
          opacity: 1,
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.border.radius,
          boxShadow: theme.border.boxShadow,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '18px 24px',
        },
        head: {
          color: theme.palette.grey[600],
          fontFamily: theme.typography.bold.monospace,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main,
              borderWidth: '1px',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.common.black,
          fontSize: '1rem',
          padding: '16px',
        },
        arrow: {
          color: theme.palette.text.primary,
        },
        popper: {
          zIndex: 10000,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: theme.palette.blueButton.hover,
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPopover-paper': {
            boxShadow: theme.border.boxShadow2,
          },
        },
      },
    },
  },
});

export default theme;
