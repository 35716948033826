import React from 'react';
import { bool, func, string } from 'prop-types';
import { Box, Typography, FormControl, RadioGroup, Radio, Tooltip, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import staticText from '../../../../../../../../localization/Application/connect-config';
import { RadioButtonLabel } from '../../../../../../../../components/Form/components/RadioGroup/RadioGroup';

import PermissionGate from '../../../../../../../../components/PermissionGate';

const BrandOptIn = ({ selectedNewBrandOptIn, updatePreviewSettings, disabled }) => {
  const theme = useTheme();

  const NewlyAddedBrandsOptions = staticText.brandManagement.displayNewlyAddedBrands;

  /* istabul ignore next */
  const updateNewlyAddedBrands = (event) => {
    const { value } = event.target;

    updatePreviewSettings({
      selectedNewBrandOptIn: value,
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      marginY={3}
      flexDirection="column"
    >
      <Typography marginY={3} variant="h3">{staticText.brandManagement.newlyAddedBrands}</Typography>
      <FormControl>
        <RadioGroup
          row
          onChange={updateNewlyAddedBrands}
        >
          {Object.keys(NewlyAddedBrandsOptions).map((option) => {
            const displayOptionName = NewlyAddedBrandsOptions[option].label;
            const displayTooltip = NewlyAddedBrandsOptions[option].tooltip;

            return (
              <PermissionGate
                key={option}
                dashboardPermission="write_connect_configuration"
                disabled
              >
                <RadioButtonLabel
                  key={option}
                  value={option}
                  label={
                    <Box display="flex" alignItems="center">
                      {displayOptionName}
                      { option === 'enabled' &&
                      <Tooltip
                        placement="top"
                        title={displayTooltip}
                      >
                        <InfoOutlined
                          htmlColor={theme.palette.grey[600]}
                        />
                      </Tooltip>
                      }
                    </Box>
                  }
                  control={<Radio />}
                  disabled={disabled}
                  checked={(!selectedNewBrandOptIn && option === 'enabled') ||
                  (selectedNewBrandOptIn === option)}
                />
              </PermissionGate>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default BrandOptIn;

BrandOptIn.propTypes = {
  selectedNewBrandOptIn: string.isRequired,
  updatePreviewSettings: func.isRequired,
  disabled: bool.isRequired,
};

BrandOptIn.defaultProps = {};
