import React from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import PlanButton from '../PlanButton/PlanButton';
import PlanHighlights from '../PlanHighlights/PlanHighlights';

import staticText from '../../../../../../localization/Application/Billing/planComparisonTable';
import { ArrowLink, Card } from '../../../../../../components';
import { Section } from '../../../../styles';

const Grid = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridColumnGap: theme.spacing(2),
  gridRowGap: theme.spacing(3),
}));

const PlanComparisonTable = ({
  baseVehicles,
  emailVerifiedAt,
  eligibleForFreeTrial,
  planName,
  setCurrentModal,
  trialActive,
  organizationId,
  setHasHandledStripeRedirect,
}) => {
  const grid = [];
  staticText.plans.forEach((plan, ind) => {
    // create each portion of a column in the grid
    const card = (
      <Card
        key={plan.planName}
        header={plan.planName}
        headerPill={
          plan.planName === 'Build' && trialActive
            ? staticText.current
            : undefined
        }
        title={plan.price.cost}
        titleAddendum={plan.price.addendum}
        subtitle={plan.planSubtitle}
      />
    );
    const button = (
      <PlanButton
        buttonTexts={staticText.buttonTexts}
        emailVerifiedAt={emailVerifiedAt}
        columnPlanName={plan.planName}
        eligibleForFreeTrial={eligibleForFreeTrial}
        key={`${planName}-${plan.planName}`}
        setCurrentModal={setCurrentModal}
        userPlanName={planName}
        trialActive={trialActive}
        organizationId={organizationId}
        setHasHandledStripeRedirect={setHasHandledStripeRedirect}
      />
    );
    const highlightSubtitle = (
      <Typography
        variant="h4"
        component="h3"
        key={plan.planFeatures.title}
      >{plan.planFeatures.title}
      </Typography>
    );
    const planHighlights = (
      <PlanHighlights
        key={plan.planHighlights[0]}
        baseVehicles={baseVehicles}
        planColumn={plan.planName}
        planHighlights={plan.planHighlights}
        planName={planName}
      />
    );

    const collection = [card, button, highlightSubtitle, planHighlights];

    // add it to the grid array in the appropriate position
    collection.forEach((item, i) => {
      grid[(i * 4) + ind] = item;
    });
  });

  return (
    <Section>
      <Typography variant="h2" sx={{ mb: 1 }}>{staticText.title}</Typography>
      <ArrowLink
        text="See a complete feature comparison"
        link="https://smartcar.com/pricing/"
        icon="launch"
      />
      <Grid>{grid}</Grid>
    </Section>
  );
};

export default PlanComparisonTable;

PlanComparisonTable.propTypes = {
  baseVehicles: oneOfType([string, number]),
  emailVerifiedAt: string,
  eligibleForFreeTrial: bool,
  planName: string.isRequired,
  setCurrentModal: func.isRequired,
  trialActive: bool,
  organizationId: string.isRequired,
  setHasHandledStripeRedirect: func.isRequired,
};

PlanComparisonTable.defaultProps = {
  baseVehicles: null,
  emailVerifiedAt: null,
  eligibleForFreeTrial: false,
  trialActive: false,
};
