import { SelectIcon } from '../../../../../../../../components';

export const dropdownFilterOptions = [
  'Engine',
  'Endpoints',
];

export const dropDownLabels = {
  inputLabel: 'Select filter type',
  engineTypeLabel: 'Select engine type',
  endpointTypeLabel: 'Select endpoint type',
};

export const disabledFilters = [
  {
    filterKey: 'disabledFilter',
    icon: SelectIcon,
    id: 'select-filter-type',
    minWidth: '134px',
    marginTop: '8px',
    marginBottom: '8px',
    height: '56px',
  },
];

export const engineFilters = [
  {
    filterKey: 'engineType',
    formTitle: 'Select engine type',
    icon: SelectIcon,
    id: 'brand-filter-engineTypes',
    minWidth: '250px',
    marginTop: '8px',
    marginBottom: '8px',
    height: '56px',
  },
];

export const endpointsFilters = [
  {
    filterKey: 'endpoints',
    formTitle: 'Select endpoint',
    icon: SelectIcon,
    id: 'brand-filter-endpoints',
    minWidth: '250px',
    marginTop: '8px',
    marginBottom: '8px',
    height: '56px',
  },
];

export const limitMaxEndpoints = 'Select up to 3 endpoints';
