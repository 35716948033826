/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { arrayOf, bool, element, func, object, shape, string, array } from 'prop-types';
import { Box, Button } from '@mui/material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import staticText from '../../../../../../localization/Application/connect-config';
import { Dialog, InlineLinks, Spinner } from '../../../../../../components';

import PermissionGate from '../../../../../../components/PermissionGate';
import { BrandList, BrandOptIn, SortOrder } from './components';
import { TabDescription, TabHeading } from '../../styles';
import { determineFilteredBrandsFromConnectConfig } from './utils/determineFilteredBrands';
import { useDelay } from '../../../../../../hooks';
import compareMakesAndAvailableFilters from './utils/compareMakesAndAvailableFilters';

const BrandManagement = ({
  actions: {
    updateSelectedMakes,
    updateConnectConfigRequest,
    updatePreviewSettings,
    updateAppliedFilters,
    updateAvailableMakesAfterAppliedFilter,
    fetchApplicationMakesRequest,
    fetchConnectConfigRequest,
    fetchBrandManagementFilterOptions,
    disableAllBrands,
  },
  applicationMakes,
  connectConfig,
  previewSettings: {
    selectedSortOrder,
    selectedNewBrandOptIn,
  },
  selectedBrandManagementFilters,
  fetchingConnectConfigErrors,
  isFetchingApplicationMakes,
  isFetchingConnectConfig,
  isUpdating,
  selectedMakes,
  upgradeMessage,
  upgradeCta,
  renderErrorMessage,
  suggestionDialogOpen,
  setSuggestionDialogOpen,
  brandManagementFilterOptions,
  brandManagementCapabilities,
  availableMakesAfterAppliedFilter,
  areAllBrandsDisabled,
}) => {
  const { afterDelay: showLoading, resetTimer } = useDelay();

  const handleSubmit = () => {
    resetTimer();
    updateConnectConfigRequest({
      supportedMakes: selectedMakes,
      ...((!connectConfig || selectedNewBrandOptIn !== connectConfig.newBrandOptIn) && {
        newBrandOptIn: selectedNewBrandOptIn === 'enabled',
      }),
      ...((!connectConfig || selectedSortOrder !== connectConfig.supportedMakesSortOrder) && {
        supportedMakesSortOrder: selectedSortOrder,
      }),
      ...((
        !connectConfig || selectedBrandManagementFilters !== connectConfig.brandManagementFilters
      ) && {
        brandManagementFilters: selectedBrandManagementFilters,
      }),
    });

    if (selectedMakes.length === 1) setSuggestionDialogOpen(true);
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'Brand Management - Supported Brands',
      form_content: {
        selectedMakes,
        supportedMakesSortOrder: selectedSortOrder,
        brandManagementFilters: selectedBrandManagementFilters,
      },
    });
  };

  const isPending = isFetchingApplicationMakes || isFetchingConnectConfig || isUpdating;
  const noChangeToSelection = () => {
    if (!connectConfig) return false;
    if (connectConfig.supportedMakes &&
      connectConfig.supportedMakes.length !== selectedMakes.length) return false;
    if (selectedSortOrder !== connectConfig.supportedMakesSortOrder) return false;
    if (selectedBrandManagementFilters !== connectConfig.brandManagementFilters) return false;
    if ((selectedNewBrandOptIn === 'enabled') !== connectConfig.newBrandOptIn) return false;

    /* istanbul ignore next */
    const previousSelection = new Set(connectConfig.supportedMakes);
    return selectedMakes.every(make => previousSelection.has(make));
  };

  // Once initial fetches are complete, determine which makes are selected
  useEffect(() => {
    if (!isFetchingApplicationMakes && !isFetchingConnectConfig && !isUpdating) {
      const initialSelectedMakes =
        (connectConfig && connectConfig.supportedMakes && connectConfig.supportedMakes.length > 0)
          ? connectConfig.supportedMakes
          : applicationMakes.map(applicationMake => applicationMake.make);


      const availableBrandsAfterAppliedFilter = determineFilteredBrandsFromConnectConfig(
        brandManagementCapabilities,
        selectedBrandManagementFilters,
      );

        // The areAllBrandsDisabled boolean check is needed here just in case
        // an user selects filter options that disables all the brands.
        // Without it, a bug will set all the `disabled` checkboxes to false
        // allowing an user to select all brands even though its not supported
      updateAvailableMakesAfterAppliedFilter(
        availableBrandsAfterAppliedFilter.length === 0 && areAllBrandsDisabled
          ? applicationMakes.map(applicationMake => applicationMake.make)
          : availableBrandsAfterAppliedFilter,
      );

      updateSelectedMakes(
        areAllBrandsDisabled ?
          []
          : compareMakesAndAvailableFilters(
            initialSelectedMakes,
            availableBrandsAfterAppliedFilter,
          ));
    }
  }, [isFetchingApplicationMakes, isFetchingConnectConfig, selectedBrandManagementFilters]);


  return (
    <div>
      <TabHeading variant="h2">{staticText.brandManagement.heading}</TabHeading>
      {upgradeMessage}
      <TabDescription>{staticText.brandManagement.description}</TabDescription>
      <div>
        <BrandList
          fetchApplicationMakesRequest={fetchApplicationMakesRequest}
          fetchConnectConfigRequest={fetchConnectConfigRequest}
          fetchBrandManagementFilterOptions={fetchBrandManagementFilterOptions}
          applicationMakes={applicationMakes}
          selectedMakes={selectedMakes || []}
          disableAllBrands={disableAllBrands}
          areAllBrandsDisabled={areAllBrandsDisabled}
          updateSelectedMakes={updateSelectedMakes}
          updateAppliedFilters={updateAppliedFilters}
          updateAvailableMakesAfterAppliedFilter={updateAvailableMakesAfterAppliedFilter}
          availableMakesAfterAppliedFilter={availableMakesAfterAppliedFilter}
          selectedBrandManagementFilters={selectedBrandManagementFilters}
          brandManagementFilterOptions={brandManagementFilterOptions}
          brandManagementCapabilities={brandManagementCapabilities}
          isPending={isPending && showLoading}
          connectConfigError={fetchingConnectConfigErrors.connectConfig}
          applicationMakesError={fetchingConnectConfigErrors.applicationMakes}
          renderErrorMessage={renderErrorMessage}
        />
        <SortOrder
          selectedSortOrder={selectedSortOrder}
          updatePreviewSettings={updatePreviewSettings}
          disabled={isPending && showLoading}
        />
        <BrandOptIn
          selectedNewBrandOptIn={selectedNewBrandOptIn}
          updatePreviewSettings={updatePreviewSettings}
          disabled={isPending && showLoading}
        />
        {fetchingConnectConfigErrors.updatingConnectConfig &&
          renderErrorMessage(fetchingConnectConfigErrors.updatingConnectConfig)}
        <Box
          display="flex"
          justifyContent="flex-end"
          marginY={3}
        >
          {upgradeCta ||
            <PermissionGate
              dashboardPermission="write_connect_configuration"
            >
              <Button
                id="publish-button"
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  isPending ||
                  !selectedMakes ||
                  selectedMakes.length === 0 ||
                  noChangeToSelection() ||
                  !!upgradeMessage
                }
                sx={{ minHeight: '40px', minWidth: '152px' }}
              >
                {isUpdating && showLoading ? <Spinner size="button" additionalClassNames="flex" /> : staticText.publish}
              </Button>
            </PermissionGate>
          }
        </Box>
        <InlineLinks text={staticText.brandManagement.global} />
      </div>
      <Dialog
        icon="info"
        open={suggestionDialogOpen}
        setDialogOpen={setSuggestionDialogOpen}
        heading={staticText.brandManagement.singleBrandSuggestion.heading}
        text={<InlineLinks text={staticText.brandManagement.singleBrandSuggestion.text} />}
        cancelText={staticText.brandManagement.singleBrandSuggestion.close}
      />
    </div>
  );
};

export default BrandManagement;

BrandManagement.propTypes = {
  actions: shape({
    updateSelectedMakes: func.isRequired,
    updateConnectConfigRequest: func.isRequired,
    updatePreviewSettings: func.isRequired,
  }).isRequired,
  applicationMakes: arrayOf(
    shape({
      make: string.isRequired,
      displayName: string.isRequired,
    })).isRequired,
  connectConfig: shape({
    supportedMakes: arrayOf(string).isRequired,
  }),
  fetchingConnectConfigErrors: shape({
    applicationMakes: string,
    connectConfig: string,
    makesByCountry: string,
    updatingConnectConfig: string,
  }).isRequired,
  isFetchingApplicationMakes: bool.isRequired,
  isFetchingConnectConfig: bool.isRequired,
  isUpdating: bool.isRequired,
  selectedMakes: arrayOf(string),
  previewSettings: shape({
    selectedSortOrder: string.isRequired,
    selectedNewBrandOptIn: string.isRequired,
  }),
  upgradeMessage: element,
  upgradeCta: element,
  renderErrorMessage: func.isRequired,
  suggestionDialogOpen: bool.isRequired,
  setSuggestionDialogOpen: func.isRequired,
  brandManagementFilterOptions: object.isRequired,
  brandManagementCapabilities: object.isRequired,
  availableMakesAfterAppliedFilter: arrayOf(string).isRequired,
  selectedBrandManagementFilters: arrayOf(array).isRequired,
  areAllBrandsDisabled: bool.isRequired,
};

BrandManagement.defaultProps = {
  connectConfig: {},
  selectedMakes: [],
  previewSettings: {
    selectedSortOrder: 'popularity',
  },
  upgradeMessage: null,
  upgradeCta: null,
};
