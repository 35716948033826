/* istanbul ignore file */
import { disabledFilters } from '../components/MultiSelect/MultiSelectConfig';

const getFilterStatusAndTitle = (filter, key, title) => {
  let isFilterActive;
  let numSelected;
  let menuItemWrapperTitle;

  if (key !== disabledFilters[0].filterKey) {
    const keys = Object.keys(filter[key]);

    numSelected = keys.reduce((acc, text) => {
      let currentTotal = acc;
      if (filter[key][text]) {
        currentTotal += 1;
      }
      return currentTotal;
    }, 0);

    isFilterActive = numSelected !== 0;

    if (keys.length === numSelected) {
      menuItemWrapperTitle = 'Any';
    } else if (isFilterActive) {
      menuItemWrapperTitle = `(${numSelected}) ${title}`;
    } else {
      menuItemWrapperTitle = title;
    }
  }

  return {
    isFilterActive,
    menuItemWrapperTitle,
    numSelected,
  };
};

export default getFilterStatusAndTitle;
