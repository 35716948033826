import React, { useEffect, useState } from 'react';
import { func, number, string, shape, bool } from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { HeaderWrapper, Badge, Delete, Label, Logo, Value } from './styles';
import { CopyButton, Dialog, PermissionGate } from '../../../../../../../../components';
import { Drawer } from '..';
import getBrandLogo from '../../../../utils/brandLogoMap';
import staticText from '../../../../../../../../localization/Application/Simulator/simulator';
import { handleVinCredentials, isAutoCreatedVehicle } from '../../../../utils/utils';

const Header = ({ vehicle, deleteVehicle, autoplay }) => {
  const vehicleIsConnected = !!vehicle.smartcarId;

  const [credentialsDrawerOpen, setCredentialsDrawerOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const {
    make, model, year, vin, username, password,
  } = vehicle;
  const theme = useTheme();

  const logo = getBrandLogo(make);
  const vehicleName = `${year} ${make.trim()} ${model.trim()}`;

  useEffect(() => {
    /* istanbul ignore next */
    if (!vehicleIsConnected) {
      handleVinCredentials(vin, setCredentialsDrawerOpen);
    }
  }, []);

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <Logo>
          <img src={logo} alt={make} />
        </Logo>
        <div>
          <Typography variant="h2">{vehicleName}</Typography>
          <Box display="flex">
            <Box display="flex" alignItems="center" mr={theme.spacing(1)}>
              <Label variant="h3">{staticText.vin}</Label>
              <Value>{vin}</Value>
              <CopyButton content={vin} showCopyText={false} color={false} />
            </Box>
            {vehicleIsConnected && (
              <Box display="flex" alignItems="center" mr={theme.spacing(1)}>
                <Label variant="h3">{staticText.vehicleId}</Label>
                <Value>{vehicle.smartcarId}</Value>
                <CopyButton content={vehicle.smartcarId} showCopyText={false} color={false} />
              </Box>
            )}
          </Box>
        </div>
      </Box>
      <Box>
        <Button
          id="credentials-drawer-btn"
          variant={vehicleIsConnected ? 'outlined' : 'contained'}
          onClick={() => setCredentialsDrawerOpen(true)}
        >
          {staticText.credentialsDrawerButton}
          {!vehicleIsConnected && (
            <Badge id="credential-badge"><p>!</p></Badge>
          )}
        </Button>
        <Drawer
          type="credentials"
          drawerOpen={credentialsDrawerOpen}
          setDrawerOpen={setCredentialsDrawerOpen}
          additionalProps={{
            username,
            password,
            vehicleIsConnected,
            isAutoplay: isAutoCreatedVehicle(vin),
          }}
        />
        {!autoplay && (
          <PermissionGate dashboardPermission="write_simulator">
            <Delete id="delete-dialog-btn" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
              <DeleteRoundedIcon color={theme.palette.mediumGray} />
            </Delete>
          </PermissionGate>
        )}
        <Dialog
          open={deleteDialogOpen}
          setDialogOpen={setDeleteDialogOpen}
          heading={staticText.deleteVehicleDialog.heading}
          text={staticText.deleteVehicleDialog.text(vehicleName)}
          submitText={staticText.deleteVehicleDialog.submitButtonText}
          handleSubmit={deleteVehicle}
          additionalProps={vehicle}
        />
      </Box>
    </HeaderWrapper>
  );
};

export default Header;

Header.propTypes = {
  vehicle: shape({
    make: string.isRequired,
    model: string.isRequired,
    year: number.isRequired,
    vin: string.isRequired,
    username: string.isRequired,
  }).isRequired,
  deleteVehicle: func.isRequired,
  autoplay: bool.isRequired,
};
