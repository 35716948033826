import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';

import { Spinner } from '../../..';
import { ImageUploadWrapper, UploadButton } from './styles';

const ImageUpload = ({
  previousImageUrl,
  description,
  specs,
  buttonText,
  disabled,
  onUpload,
  acceptedTypes,
  maxSizeInBytes,
  updating,
  uploadingText,
  uploadError,
  clearUploadError,
}) => {
  const theme = useTheme();
  const [error, setError] = useState('');

  const handleUpload = (event) => {
    setError('');
    clearUploadError('updatingAppLogo');
    const { files: [image] } = event.target;
    if (!acceptedTypes.includes(image.type)) {
      setError('Invalid image type. Please convert or try another image.');
    } else if (image.size > maxSizeInBytes) {
      setError('File size too large. Please resize or try another image.');
    } else {
      onUpload(image);
    }
  };

  useEffect(() => {
    const fileInput = document.getElementById('image-upload-input');
    fileInput.addEventListener('change', handleUpload);
    return () => {
      setError('');
      fileInput.removeEventListener('change', handleUpload);
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      setError('');
    }
  }, [disabled]);

  useEffect(() => {
    if (uploadError) {
      setError(uploadError);
    }
    return () => {
      clearUploadError('updatingAppLogo');
    };
  }, [uploadError]);

  return (
    <ImageUploadWrapper hasLogo={!!previousImageUrl}>
      <div className="logo-image-container">
        {previousImageUrl && <img src={previousImageUrl} alt="Your uploaded app logo" />}
      </div>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <label htmlFor="image-upload-input" id="image-upload-input-label">
          <UploadButton disabled={disabled}>
            <Typography variant="bold" display="flex">
              {updating ? (
                <React.Fragment>
                  <Spinner spinnerColor={theme.palette.primary.main} size="xs" additionalClassNames="flex" />
                  <Box marginLeft={1}>{uploadingText}</Box>
                </React.Fragment>
              ) : (
                buttonText
              )}
            </Typography>
          </UploadButton>
        </label>
        <input
          id="image-upload-input"
          type="file"
          accept={acceptedTypes.join(', ')}
          disabled={disabled}
        />
        {description && <Typography variant="caption" width="400px">{description}</Typography>}
        <Typography variant="caption" width="400px">{specs}</Typography>
        {error && <Typography variant="caption" color="error">{error}</Typography>}
      </Box>
    </ImageUploadWrapper>
  );
};

export default ImageUpload;

ImageUpload.propTypes = {
  onUpload: func.isRequired,
  previousImageUrl: string,
  buttonText: string.isRequired,
  description: string,
  specs: string.isRequired,
  acceptedTypes: arrayOf(string).isRequired,
  maxSizeInBytes: number.isRequired,
  disabled: bool.isRequired,
  updating: bool.isRequired,
  uploadingText: string.isRequired,
  uploadError: string,
  clearUploadError: func.isRequired,
};

ImageUpload.defaultProps = {
  previousImageUrl: null,
  description: '',
  uploadError: '',
};
