import { Box, useTheme } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import _ from 'lodash';
import { func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import appConfig from 'appConfig';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  AccountTrialCountdownTracker,
  Feedback,
  Modal,
  RegisterApplication,
  SelectAppsModal,
  Spinner,
  Toast,
  UpsellModal,
} from '../../components';
import applicationsProps, { defaultProps as applicationsDefaultProps } from '../../types/applications';
import {
  ApplicationNotFound,
  Applications,
  Billing,
  Configuration,
  Connect,
  Logs,
  Members,
  OrgSettings,
  Overview,
  SidebarNav,
  Simulator,
  SuspendedOrg,
  TopbarNav,
  UserProfile,
  UserSecurity,
  Vehicles,
  Webhooks,
} from './components';

import staticText from '../../localization/Application/application';
import billingStaticText from '../../localization/Application/Billing/billing';
import { setScrollAbility } from '../../services/styleUtils';
import { daysLeftInTrial, isInTrial } from './components/Billing/utils/textFormatters';
import { Main, MainWrapper, SidebarWrapper, TopbarWrapper } from './styles';

import { gatedFeatureData, isSelfServe } from '../../services/featureGate';
import FEATURES from '../../services/featureGate/features';
import { launchChat, openChat, shutdownChat, SUPPORT_CHAT_ID } from '../../services/front/front';
import utils from '../utils';

import api from '../../services/api/api';
import { getOrgApps, updateLocalStorageWithMostRecentApp } from './utils';

const Application = ({
  actions,
  userContext,
  organization,
  selectedApplication,
  selectedOrganization,
  applications,
  billingInfo,
  lockedApplications,
  isFetching,
  isFetchingOrganizations,
  history,
  match,
  applicationsErrorMessage,
  secretErrorMessage,
  lockedApplicationsErrorMessage,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activityStart, setActivityStart] = useState(Date.now());
  const [sidebarNavExpanded, setSidebarNavExpanded] = useState(true);
  const theme = useTheme();

  const { applicationId } = match.params;
  const pathParts = history.location.pathname.split('/');
  const basePath = pathParts[1];
  const pagePath = pathParts[pathParts.length - 1];

  const applicationIds = Object.keys(applications);
  const orgApps = getOrgApps(applications, organization.id);
  const ownedAppIds = Object.keys(orgApps);
  const appLimit = gatedFeatureData(FEATURES.APPLICATIONS, organization.featureSetId);
  const isAppAmountValid = Boolean(!appLimit)
    || ownedAppIds.length - lockedApplications.length === appLimit
    || (ownedAppIds.length <= appLimit && lockedApplications.length === 0);
  const isValidApp = basePath === 'apps' && applicationIds.includes(applicationId) &&
    !lockedApplications.includes(applicationId);
  const maxAppCountReached = Object.keys(orgApps).length >= appLimit;

  const currentOrgFeatureSetId = organization.featureSetId;

  // Suspended featureSetId ('216cca36-5028-4ea8-aeb2-d7acdcaf20b4')
  const isSuspendedFeatureSetId = currentOrgFeatureSetId === '216cca36-5028-4ea8-aeb2-d7acdcaf20b4';

  const emailSupport = ({ title, description }) => {
    const content = {
      subject: title,
      message: description,
      developerId: selectedOrganization,
      dashboardUserId: userContext.dashboardUserId,
      applicationId: selectedApplication,
      name: `${userContext.firstName} ${userContext.lastName}`,
      email: userContext.email,
    };
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'contact support attempt via CommandAI',
      form_content: content,
    });
    actions.sendSupportRequest(content);
  };

  const { commandAiEnabled } = useFlags();

  useEffect(() => {
    if (utils.forceVerification(
      userContext.developerCreatedAt,
      userContext.developerEmailVerifiedAt,
    )) {
      history.push('/verify-email');
    }

    actions.fetchApplications();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        reportToSegment(types.TRACK, eventNames.applicationActive, {
          msActive: Date.now() - activityStart,
          applicationId,
        });
      } else {
        setActivityStart(Date.now());
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    reportToSegment(types.TRACK, eventNames.applicationLoaded, {
      ...(applicationId && { applicationId }),
    });

    // componentWillUnmount
    return () => {
      reportToSegment(types.TRACK, eventNames.applicationActive, {
        msActive: Date.now() - activityStart,
        applicationId,
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      shutdownChat();
      // close Command AI for the user
      if (window.CommandBar) {
        window.CommandBar.shutdown();
      }
    };
  }, []);

  useEffect(() => {
    if (commandAiEnabled) {
      window.CommandBar.boot(
        userContext.dashboardUserId,
        {
          email: userContext.email,
          firstName: userContext.firstName,
          lastName: userContext.lastName,
          env: process.env.NODE_ENV,
          baseURL: _.get(appConfig, 'baseURL'),
        },
        { hmac: userContext.commandAIHmac },
      );
      // set up router for Command AI
      const routerFunc = newUrl => history.push(newUrl);
      window.CommandBar.addRouter(routerFunc);
      // hand off chat to Front
      window.CommandBar.addCallback('openChat', openChat);
      window.CommandBar.addCallback('emailSupport', emailSupport);
    }
  }, [commandAiEnabled]);

  useEffect(() => {
    if (commandAiEnabled) {
      const metaData = {
        dashboardUserId: userContext.dashboardUserId,
        selectedApplication,
        selectedOrganization,
        hasChatSupport: !gatedFeatureData(FEATURES.CHAT_SUPPORT, currentOrgFeatureSetId),
      };
      window.CommandBar.addMetadataBatch(metaData);
      window.CommandBar.setUserProperties({
        selectedApplication,
        selectedOrganization,
      });
    }
  }, [selectedApplication, selectedOrganization, commandAiEnabled]);

  useEffect(() => {
    if (
      organization &&
      organization.featureSetId &&
      !gatedFeatureData(FEATURES.CHAT_SUPPORT, organization.featureSetId)
    ) {
      launchChat(SUPPORT_CHAT_ID, {
        applicationId,
        developerId: selectedOrganization,
        dashboardUserId: userContext.dashboardUserId,
        firstName: userContext.firstName,
        lastName: userContext.lastName,
        email: userContext.email,
        useDefaultLauncher: !commandAiEnabled,
      });
    }
  }, [selectedOrganization, commandAiEnabled]);

  useEffect(() => {
    // Handle application selection
    if (basePath === 'apps' && selectedApplication !== applicationId && isValidApp) {
      // set most recent orgId/AppId for a given user
      updateLocalStorageWithMostRecentApp({
        selectedOrganization,
        applicationId,
        userContext,
      });
      actions.selectApplication(applicationId);
      actions.fetchApplications();
    }
  }, [selectedApplication, applicationId]);

  useEffect(() => {
    // organization selection
    const appOrganizationId = _.get(applications, [applicationId, 'organizationId']);
    // set most recent orgId/AppId for a given user
    updateLocalStorageWithMostRecentApp({
      selectedOrganization,
      applicationId,
      userContext,
    });
    if (appOrganizationId && appOrganizationId !== selectedOrganization) {
      actions.selectOrganization(appOrganizationId);
      history.push('/');
    }
  }, [selectedApplication, selectedOrganization]);

  useEffect(() => {
    // Error handling toasts
    if (applicationsErrorMessage) {
      Toast(applicationsErrorMessage, 'warn');
    }

    if (secretErrorMessage) {
      Toast(secretErrorMessage, 'warn');
    }

    if (lockedApplicationsErrorMessage) {
      Toast(lockedApplicationsErrorMessage, 'warn');
    }
  }, [applicationsErrorMessage, secretErrorMessage, lockedApplicationsErrorMessage]);

  const toggleExpandedMenu = () => {
    setSidebarNavExpanded(!sidebarNavExpanded);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: sidebarNavExpanded ? 'close menu' : 'open menu',
      text: sidebarNavExpanded ? '< [close nav]' : '> [open nav]',
    });
  };

  const toggleModal = (withTracking = true) => {
    const segmentReport = maxAppCountReached
      ? staticText.upgradeModal
      : staticText.modal;
    if (withTracking) {
      reportToSegment(
        types.TRACK,
        eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`],
        segmentReport,
      );
    }
    setScrollAbility(modalOpen);
    setModalOpen(!modalOpen);
  };

  const feedbackContent = [];
  // Only displaying payment error for self-serve feature sets
  if (billingInfo.delinquent && isSelfServe(organization.featureSetId)) {
    const getStripePortalURL = async () => {
      const URL = (await api.fetchStripeUrl(organization.id, 'portal', billingInfo.stripeCustomerId));
      window.location.href = URL;
    };

    feedbackContent.push({
      key: 'paymentError',
      message: billingStaticText.paymentErrorFeedback,
      type: 'warn',
      buttonOnClick: getStripePortalURL,
      buttonText: 'View',
    });
  }

  const selectApplicationsModal = !isAppAmountValid && applicationId && (
    <SelectAppsModal
      orgApps={orgApps}
      applicationId={applicationId}
      featureSetId={organization.featureSetId}
    />
  );

  const showTrialBanner = isInTrial(billingInfo.planName);

  let application;
  if (basePath === 'apps' && !isValidApp) {
    application = (
      <div>
        {modalOpen && !maxAppCountReached && (
          <Modal
            {...{
              title: staticText.modal.title,
              content: (
                <RegisterApplication
                  toggleModal={toggleModal}
                  applicationNames={_.map(orgApps, 'name')}
                />
              ),
            }}
            toggleModal={toggleModal}
          />
        )}
        {modalOpen && maxAppCountReached && (
          <UpsellModal
            toggleModal={toggleModal}
            feature={FEATURES.APPLICATIONS}
          />
        )}
        <ApplicationNotFound
          applications={orgApps}
          toggleModal={toggleModal}
          applicationId={applicationId}
          pathname={history.location.pathname}
          organizationId={organization.id}
          dashboardRole={organization.dashboardRole}
        />
      </div>
    );
  } else {
    application = (
      <Box display="flex" flexDirection="column" alignItems="stretch">
        <TopbarWrapper>
          {showTrialBanner && (
            <AccountTrialCountdownTracker
              message={`${staticText.trialBanner.message} ${daysLeftInTrial(
                billingInfo.billingPeriodEndDate,
              )} ${staticText.trialBanner.durationType}`}
            />
          )}
          <TopbarNav
            pathname={history.location.pathname}
            orgApps={orgApps}
            view={basePath}
          />
        </TopbarWrapper>
        <Box
          display="flex"
          marginTop={showTrialBanner ?
            `calc(${theme.height.topbar} + ${theme.height.banner})` :
            theme.height.topbar}
        >
          <SidebarWrapper onClick={toggleExpandedMenu}>
            <SidebarNav
              pathname={history.location.pathname}
              applicationId={selectedApplication}
              menuExpanded={sidebarNavExpanded}
              toggleExpandedMenu={toggleExpandedMenu}
              view={basePath}
            />
          </SidebarWrapper>
          <MainWrapper sidebarNavExpanded={sidebarNavExpanded}>
            <Main>
              {feedbackContent && pagePath !== 'billing' &&
                <Box sx={{ maxWidth: pagePath === 'vehicles' || pagePath === 'logs' ? '1600px' : theme.width.content }}>
                  {feedbackContent.map((props, index) => (
                    <Feedback
                      {...props}
                      additionalClassNames={[
                        'm-t-sm',
                        index + 1 === feedbackContent.length ? 'm-b-med' : 'm-b-sm',
                      ]}
                    />
                  ))}
                </Box>
              }
              {
                isSuspendedFeatureSetId ?
                (
                  <SuspendedOrg />
                )
                : (
                  <Switch>
                    {/* APPS ROUTES */}
                    <Route
                      path="/apps/:applicationId/overview"
                      component={Overview}
                    />
                    <Route
                      path="/apps/:applicationId/configuration"
                      component={Configuration}
                    />
                    <Route
                      path="/apps/:applicationId/connect"
                      component={Connect}
                    />
                    <Route
                      path="/apps/:applicationId/webhooks"
                      component={Webhooks}
                    />
                    <Route
                      path="/apps/:applicationId/simulator/:vin"
                      component={Simulator}
                    />
                    <Route
                      path="/apps/:applicationId/simulator"
                      component={Simulator}
                    />
                    <Route
                      path="/apps/:applicationId/logs"
                      component={Logs}
                    />
                    <Route
                      path="/apps/:applicationId/vehicles"
                      component={Vehicles}
                    />
                    <Route
                      path="/team/applications"
                      component={Applications}
                    />
                    <Route
                      path="/team/settings"
                      component={OrgSettings}
                    />
                    <Route
                      path="/team/members"
                      component={Members}
                    />
                    <Route
                      path="/team/billing"
                      component={Billing}
                    />
                    <Route
                      path="/user/profile"
                      component={UserProfile}
                    />
                    <Route
                      path="/user/security"
                      component={UserSecurity}
                    />

                    <Redirect
                      to={
                    basePath === 'apps'
                      ? '/apps/:applicationId/overview'
                      : '/team/applications'
                  }
                    />
                  </Switch>
                  )
              }
            </Main>
          </MainWrapper>
        </Box>
      </Box>
    );
  }

  return (isFetching || isFetchingOrganizations) ? (
    <Spinner size="page" delay={200} />
  ) : (
    <React.Fragment>
      {application}
      {selectApplicationsModal}
    </React.Fragment>
  );
};

export default Application;

Application.propTypes = {
  ...applicationsProps,
  actions: shape({
    fetchApplications: func.isRequired,
    selectApplication: func.isRequired,
    sendSupportRequest: func.isRequired,
  }),
  match: shape({
    params: shape({
      applicationId: string,
    }).isRequired,
  }).isRequired,
  history: shape({
    location: shape({
      pathname: string.isRequired,
    }).isRequired,
  }).isRequired,
};

Application.defaultProps = {
  ...applicationsDefaultProps,
};
