/* eslint-disable react/forbid-prop-types */
/* istanbul ignore file */
import React from 'react';
import { arrayOf, func, shape, object, array, string } from 'prop-types';
import staticText from '../../../../../../../../localization/Application/connect-config';

import { Modal } from '../../../../../../../../components';
import MultiSelect from '../MultiSelect/MultiSelect';

const FiltersModal = ({
  brandManagementFilterOptions,
  updateSelectedMakes,
  updateAppliedFilters,
  selectedMakes,
  updateAvailableMakesAfterAppliedFilter,
  brandManagementCapabilities,
  closeModal,
  filterPills,
  setFilterPills,
  applyFilters,
}) => {
  const addNewFilterText = staticText.brandManagement.addFilter;

  const content = (
    <div>
      <MultiSelect
        applyFilters={applyFilters}
        updateAvailableMakesAfterAppliedFilter={updateAvailableMakesAfterAppliedFilter}
        updateSelectedMakes={updateSelectedMakes}
        updateAppliedFilters={updateAppliedFilters}
        brandManagementCapabilities={brandManagementCapabilities}
        brandManagementFilterOptions={brandManagementFilterOptions}
        closeModal={closeModal}
        selectedMakes={selectedMakes}
        filterPills={filterPills}
        setFilterPills={setFilterPills}
      />
    </div>
  );

  return (
    <Modal
      title={addNewFilterText}
      content={content}
      toggleModal={() => closeModal()}
    />
  );
};

export default FiltersModal;

FiltersModal.propTypes = {
  closeModal: func.isRequired,
  brandManagementFilterOptions: shape({
    engineType: object.isRequired,
    endpoints: object.isRequired,
  }).isRequired,
  updateSelectedMakes: func.isRequired,
  updateAppliedFilters: func.isRequired,
  updateAvailableMakesAfterAppliedFilter: func.isRequired,
  brandManagementCapabilities: object.isRequired,
  filterPills: arrayOf(array).isRequired,
  selectedMakes: arrayOf(string).isRequired,
  setFilterPills: func.isRequired,
  applyFilters: func.isRequired,
};

