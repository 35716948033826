/* eslint-disable react/no-array-index-key */
/* istanbul ignore file */
import React from 'react';
import { arrayOf, func, string } from 'prop-types';
import { Box, Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  padding: '6px 8px',
  alignItems: 'center',
  borderRadius: '4px',
  border: '1px solid var(--Grey-2, #E4E7EB)',
  background: '#FAFAFA',

  '.styled-span': {
    margin: '0 8px',
  },
}));

export const StyledButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'normal',
  minWidth: 'fit-content',
}));

const FilterPill = ({ filterPill, deleteSelectedFilter }) => {
  const pillData = filterPill.map((word, index) => (
    <Box display="inline-block" minWidth="max-content" key={`${filterPill}-${index}`}>
      { index > 0 &&
        <span className="styled-span">
          <strong> or </strong>
        </span>
      }
      {word}
    </Box>
  ));

  return (
    <StyledBox>
      {pillData}
      <StyledButton
        endIcon={<HighlightOffIcon />}
        onClick={() => deleteSelectedFilter(filterPill)}
      />
    </StyledBox>
  );
};

export default FilterPill;

FilterPill.propTypes = {
  filterPill: arrayOf(string).isRequired,
  deleteSelectedFilter: func.isRequired,
};
