import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import { Checkbox, PermissionGate } from '../../../../../../../../components';
import staticText from '../../../../../../../../localization/Application/connect-config';

const RegionList = ({
  region,
  countries,
  selectedCountries,
  selectRegion,
  clearRegion,
  updateSelectedCountries,
  connectConfigError,
  renderErrorMessage,
  isPending,
}) => {
  const theme = useTheme();

  const handleChange = ({ target: { name, checked } }) => {
    const updatedCountries = [...selectedCountries];
    if (checked) {
      updatedCountries.push(name);
    } else {
      updatedCountries.splice(updatedCountries.indexOf(name), 1);
    }
    updateSelectedCountries(updatedCountries);
  };

  const selectAll = () => {
    selectRegion(region);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[connect config - countries] ${region} - ${staticText.selectAll}`,
    });
  };

  const clearAll = () => {
    clearRegion(region);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[connect config - countries] ${region} - ${staticText.clearAll}`,
    });
  };

  const countryNameEdit = (country) => {
    if (country === 'United States of America') return 'United States';
    if (country === 'Bosnia and Herzegovina') return 'Bosnia and Herz...';
    return country;
  };

  return (
    <Box marginBottom={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h3">{region}</Typography>
        <PermissionGate dashboardPermission="write_connect_configuration">
          <div>
            <Button
              id="select-all-button"
              variant="outlined"
              onClick={selectAll}
              disabled={isPending}
            >
              {staticText.selectAll}
            </Button>
            <Button
              id="clear-all-button"
              variant="outlined"
              onClick={clearAll}
              disabled={isPending}
              sx={{ marginLeft: theme.spacing(2) }}
            >
              {staticText.clearAll}
            </Button>
          </div>
        </PermissionGate>
      </Box>
      {connectConfigError && renderErrorMessage(connectConfigError)}
      <div>
        <Grid container spacing={theme.spacing(2)}>
          {countries.map(country => (
            <Grid key={country.code} item xs={4}>
              <PermissionGate
                dashboardPermission="write_connect_configuration"
                disabled
              >
                <Checkbox
                  inputName={country.code}
                  text={countryNameEdit(country.displayName)}
                  handleChange={handleChange}
                  checked={selectedCountries.includes(country.code)}
                  disabled={isPending}
                  icon={`/assets/icons/countries/${country.code}.svg`}
                />
              </PermissionGate>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default RegionList;

RegionList.propTypes = {
  region: string.isRequired,
  countries: arrayOf(shape({
    code: string.isRequired,
    region: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  selectedCountries: arrayOf(string).isRequired,
  selectRegion: func.isRequired,
  clearRegion: func.isRequired,
  updateSelectedCountries: func.isRequired,
  isPending: bool.isRequired,
  connectConfigError: string,
  renderErrorMessage: func.isRequired,
};

RegionList.defaultProps = {
  connectConfigError: '',
};
