import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { SimulatorHome, CreateVehicle, SelectTrip, SimulateVehicle } from './components';
import { Disclaimer, Feedback, Spinner, UpgradeButton } from '../../../../components';
import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import staticText from '../../../../localization/Application/Simulator/home';
import { isAutoCreatedVehicle } from './utils/utils';

const Simulator = ({
  actions: {
    fetchRegionCompatibleVehicles,
    fetchAllSimulatedVehicles,
  },
  allCompatibleVehicles,
  appFeatureSetId,
  isFetchingSimulator,
  isFetchingSimulatorTrip,
  match,
  rolePermissions,
  selectedRegion,
  selectedVehicle,
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [searchType, setSearchType] = useState('');
  const theme = useTheme();
  const { applicationId } = match.params;

  const canWriteBilling = rolePermissions.includes('write_billing');
  const isGated = gatedFeatureData(FEATURES.VEHICLE_SIMULATOR, appFeatureSetId);

  const messageByPermission = canWriteBilling ?
    staticText.featureGated.description : staticText.featureGated.contactOwnersToUpgrade;
  const upgradeMessage = isGated ? <Feedback type="info" message={messageByPermission} /> : null;

  const upgradeCta = isGated ?
    (<UpgradeButton
      text={staticText.featureGated.buttonText}
      feature={FEATURES.VEHICLE_SIMULATOR}
    />)
    : null;

  /* istanbul ignore next */
  const determineSimulatorView = () => {
    // users can still access their autoplay vehicle even when feature is gated
    if (!isGated ||
      (selectedVehicle && selectedVehicle.vin && isAutoCreatedVehicle(selectedVehicle.vin))
    ) {
      // if we have a selected vehicle with a selected trip type, go to the simulate screen
      const tripType = selectedVehicle.simulatedVehicleTripType || '';
      if (selectedVehicle.id && tripType) {
        return (<SimulateVehicle
          applicationId={applicationId}
          tripType={tripType}
          setIsFetching={setIsFetching}
          showUpgradeMessage={isGated}
          canWriteBilling={canWriteBilling}
        />);
        // if we have a selected vehicle without a selected trip type, go to trip select
      } else if (selectedVehicle.id) {
        return <SelectTrip applicationId={applicationId} setIsFetching={setIsFetching} />;
      }
    }
    // else go to simulator home
    return <Redirect to={`/apps/${applicationId}/simulator`} />;
  };

  useEffect(() => {
    if (match.params.vin !== 'create-vehicle') {
      const selectedVin = selectedVehicle.vin === match.params.vin ? '' : match.params.vin;
      fetchAllSimulatedVehicles(applicationId, selectedVin);
    }
  }, [applicationId, match.params.vin]);

  useEffect(() => {
    if (!Object.keys(allCompatibleVehicles).includes(selectedRegion)) {
      fetchRegionCompatibleVehicles(applicationId, selectedRegion);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (!isFetchingSimulator.simulatedVehicles &&
      !isFetchingSimulator.creatingVehicle &&
      !isFetchingSimulatorTrip.vehicleTrip) {
      setIsFetching(false);
    }
  }, [
    isFetchingSimulator.simulatedVehicles,
    isFetchingSimulator.creatingVehicle,
    isFetchingSimulatorTrip.vehicleTrip,
  ]);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '900px' }}>
      {isFetching ? (
        <Spinner spinnerColor={theme.palette.grey[200]} size="small" additionalClassNames="simulator" delay={200} />
      ) : (
        <Box>
          <Switch>
            <Route path={`/apps/${applicationId}/simulator/create-vehicle`}>
              <CreateVehicle
                applicationId={applicationId}
                searchType={searchType}
                setSearchType={setSearchType}
                setIsFetching={setIsFetching}
                upgradeMessage={upgradeMessage}
                upgradeCta={upgradeCta}
              />
            </Route>
            <Route path={`/apps/${applicationId}/simulator/:vin`}>
              {determineSimulatorView()}
            </Route>
            <Route path={`/apps/${applicationId}/simulator`}>
              <SimulatorHome
                applicationId={applicationId}
                upgradeMessage={upgradeMessage}
                canWriteBilling={canWriteBilling}
              />
            </Route>
          </Switch>
          <div style={{ marginLeft: '2px' }}>
            <Disclaimer />
          </div>
        </Box>

      )}
    </Box>
  );
};

export default Simulator;

Simulator.propTypes = {
  actions: shape({
    fetchRegionCompatibleVehicles: func.isRequired,
    fetchAllSimulatedVehicles: func.isRequired,
    clearSimulatedVehicles: func.isRequired,
  }).isRequired,
  allCompatibleVehicles: object.isRequired,
  appFeatureSetId: string.isRequired,
  isFetchingSimulator: shape({
    simulatedVehicles: bool.isRequired,
  }).isRequired,
  isFetchingSimulatorTrip: shape({
    vehicleTrip: bool.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      applicationId: string.isRequired,
    }).isRequired,
  }).isRequired,
  rolePermissions: arrayOf(string).isRequired,
  selectedRegion: string.isRequired,
  selectedVehicle: shape({
    id: string,
    vin: string,
    year: number,
    region: string,
    make: string,
    model: string,
    endpointsWithLatency: arrayOf(shape({
      path: string.isRequired,
      latencyLowerBound: number.isRequired,
      latencyUpperBound: number.isRequired,
      method: string.isRequired,
    })),
    username: string,
    simulatedVehicleTripId: string,
    simulatedVehicleTripType: string,
    simulatedVehicleTripStatus: string,
    smartcarId: string,
  }).isRequired,
  simulatedVehicles: object.isRequired,
};
