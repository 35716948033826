import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConnectPreview from './ConnectPreview';
import { actions } from '../../reducers';

function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
    application: state.applications.applications[state.applications.selectedApplication],
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchMakesByCountryRequest,
    updatePreviewSettings,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchMakesByCountryRequest,
        updatePreviewSettings,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectPreview);
