function compareMakesAndAvailableFilters(array1, array2) {
  // Convert the second array to a Set for quick lookup
  const set2 = new Set(array2);

  // Filter the first array to include only elements that are also in the second array
  const commonElements = array1.filter(element => set2.has(element));

  return commonElements;
}

export default compareMakesAndAvailableFilters;
