import _ from 'lodash';
import appConfig from 'appConfig';
import api from '../api/api';

export const SALES_CHAT_ID = _.get(appConfig, 'frontChat.salesChatId');
export const SUPPORT_CHAT_ID = _.get(appConfig, 'frontChat.supportChatId');

/* istanbul ignore file */
export const launchChat = async (chatId, fields) => {
  if (window.FrontChat) {
    const { data } = await api.verifyChatIdentity({ chatId, email: fields.email });

    window.FrontChat('init', {
      chatId,
      useDefaultLauncher: fields.useDefaultLauncher,
      email: fields.email,
      name: `${fields.firstName} ${fields.lastName}`,
      userHash: data,
      customFields: {
        'Client ID': fields.applicationId,
        'Developer ID': fields.developerId,
        'Dashboard User ID': fields.dashboardUserId,
      },
    });
  }
};

export const shutdownChat = () => {
  if (window.FrontChat) {
    window.FrontChat('shutdown');
  }
};

export const openChat = () => {
  if (window.FrontChat) {
    window.FrontChat('show');
  }
};
