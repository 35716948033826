import { styled } from '@mui/material/styles';

export const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  '& > .MuiTypography-h3': {
    marginBottom: theme.spacing(3),
  },
  minWidth: '350px',
  boxShadow: theme.border.boxShadow2,
  borderRadius: theme.border.radius,
}));

export const CheckboxContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'numCheckboxes' && prop !== 'columns',
})(({ theme, columns, numCheckboxes }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gridColumnGap: theme.spacing(3),
  gridTemplateRows: `repeat(${Math.ceil(numCheckboxes / columns)}, 1fr)`,
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(2),

  button: {
    marginRight: theme.spacing(1),
  },
}));
