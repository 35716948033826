/* istanbul ignore file */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { arrayOf, array, object, func, shape } from 'prop-types';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { dropdownFilterOptions, endpointsFilters, engineFilters, dropDownLabels } from './MultiSelectConfig';
import getFilterStatusAndTitle from '../../../BrandManagement/utils/getFilterStatusAndTitle';
import staticText from '../../../../../../../../localization/Application/connect-config';

import { SelectIcon } from '../../../../../../../../components';
import FilterMenu from './components/FilterMenu';

const MultiSelect = ({
  brandManagementFilterOptions,
  filterPills,
  applyFilters,
}) => {
  const theme = useTheme();
  const [newFormState, setNewFormState] = useState(brandManagementFilterOptions);
  const [firstFilterType, setFirstFilterType] = useState('');
  const [secondFilterType, setSecondFilterType] = useState('');
  const intialValues = brandManagementFilterOptions;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // this first action clears (resets) the filter options
    // if an user changes the first dropdown type
    setNewFormState(intialValues);
    setFirstFilterType(value);
    setSecondFilterType();
  };

  const getHandleChange = filterKey => (newValue) => {
    setNewFormState({
      ...newFormState,
      [filterKey]: newValue,
    });
  };

  const addRemainingDropdownOption = () => {
    if (firstFilterType === dropdownFilterOptions[0]) {
      return setSecondFilterType(endpointsFilters);
    }
    return setSecondFilterType(engineFilters);
  };

  return (
    <div>
      <FormControl sx={{ margin: theme.spacing(1, 1, 1, 0), width: 200 }}>
        <InputLabel id="filter-type-input-label">{dropDownLabels.inputLabel}</InputLabel>
        <Select
          id="demo-multiple-checkbox"
          value={firstFilterType}
          label="Select filter type"
          labelId="demo-multiple-checkbox-label"
          variant="outlined"
          onChange={handleChange}
          IconComponent={SelectIcon}
          renderValue={() => (firstFilterType || 'Select filter type')}
          MenuProps={{
            style: { zIndex: 2147483647 },
          }}
        >
          {dropdownFilterOptions.map(name => (
            <MenuItem key={name} value={name}>{name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Allows an user the options of endpoint or engine when choosing filter type */}
      {
        !firstFilterType &&
        <FormControl sx={{ m: 1, width: 250 }} disabled>
          <InputLabel id="disabled-dropdown-label">{dropDownLabels.inputLabel}</InputLabel>
          <Select
            id="disabled-dropdown-label-id"
            label="Select filter type"
            labelId="disabled-dropdown-label"
            IconComponent={SelectIcon}
            disabled
          />
        </FormControl>
      }

      {/* If engine type is selected, show engine type filter options in the dropdown */}
      {
        firstFilterType === dropdownFilterOptions[0] &&
        engineFilters.map(({
          filterKey, icon, formTitle, id, minWidth, marginTop, marginBottom, height,
        }) => {
          const {
            isFilterActive,
            numSelected,
            menuItemWrapperTitle,
          } = getFilterStatusAndTitle(newFormState, filterKey, formTitle);

          return (
            <FilterMenu
              filterKey={filterKey}
              icon={icon}
              formTitle={formTitle}
              id={id}
              minWidth={minWidth}
              marginTop={marginTop}
              marginBottom={marginBottom}
              height={height}
              newFormState={newFormState}
              getHandleChange={getHandleChange}
              isFilterActive={isFilterActive}
              numSelected={numSelected}
              menuItemWrapperTitle={menuItemWrapperTitle}
            />
          );
        })
      }

      {/* If endpoint type is selected, show endpoint type filter options in the dropdown */}
      {
        firstFilterType === dropdownFilterOptions[1] &&
        endpointsFilters.map(({
          filterKey, icon, formTitle, id, minWidth, marginTop, marginBottom, height,
        }) => {
          const {
            isFilterActive,
            numSelected,
            menuItemWrapperTitle,
          } = getFilterStatusAndTitle(newFormState, filterKey, formTitle);

          return FilterMenu({
            filterKey,
            icon,
            formTitle,
            id,
            minWidth,
            marginTop,
            marginBottom,
            height,
            isFilterActive,
            numSelected,
            menuItemWrapperTitle,
            newFormState,
            getHandleChange,
          });
        })
      }

      {/* Show the leftover filter type only if there are not already 3 filters applied */}
      {
        secondFilterType &&
        secondFilterType.map(({
          filterKey, icon, formTitle, id, minWidth, marginTop, marginBottom, height,
        }) => {
          const {
            isFilterActive,
            numSelected,
            menuItemWrapperTitle,
          } = getFilterStatusAndTitle(newFormState, filterKey, formTitle);

          return (
            <Box display="flex" alignItems="center">
              <Typography>and</Typography>
              <FormControl sx={{ m: 1, width: 250 }}>
                <InputLabel id="disabled-dropdown-label">
                  {
                    secondFilterType === engineFilters ?
                    dropdownFilterOptions[0] : dropdownFilterOptions[1]
                  }
                </InputLabel>
                <Select
                  labelId="disabled-dropdown-label"
                  id="disabled-dropdown-label-id"
                  MenuProps={{
                    style: { zIndex: 2147483647 },
                  }}
                  IconComponent={SelectIcon}
                  disabled
                />
              </FormControl>

              {
                FilterMenu({
                  filterKey,
                  icon,
                  formTitle,
                  id,
                  minWidth,
                  marginTop,
                  marginBottom,
                  height,
                  isFilterActive,
                  numSelected,
                  menuItemWrapperTitle,
                  newFormState,
                  getHandleChange,
                })
              }
            </Box>
          );
        })
      }
      <Button
        startIcon={<AddIcon />}
        id="add-filter-rule-button"
        variant="text"
        disabled={!firstFilterType || secondFilterType || filterPills.length > 1}
        onClick={addRemainingDropdownOption}
        sx={{ mb: 1 }}
      >
        {staticText.brandManagement.addRule}
      </Button>
      <Box display="flex" justifyContent="flex-end">
        <Button
          id="apply-filter-button"
          variant="contained"
          onClick={() => applyFilters(newFormState)}
          disabled={filterPills.length > 2}
        >
          {staticText.brandManagement.apply}
        </Button>
      </Box>
    </div>
  );
};

export default MultiSelect;

MultiSelect.propTypes = {
  brandManagementFilterOptions: shape({
    engineType: object.isRequired,
    endpoints: object.isRequired,
  }).isRequired,
  applyFilters: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterPills: arrayOf(array).isRequired,
};
