import React from 'react';
import { CheckRounded } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { alertTriangleIconYellow } from '../../../../../../assets/icons';
import {
  Alert,
  Border,
  Card,
  Cardset,
  Container,
  Header,
  Highlights,
  Subtitle,
  Addendum,
} from './styles';
import staticText from '../../../../../../localization/Application/Billing/businessLegacyCard';

// This component can be removed when there are no more Business (legacy) plans
const BusinessLegacyCard = () => {
  return (
    <Container>
      <Header>
        <Typography variant="h2">{staticText.heading}</Typography>
        <Tooltip title={staticText.tooltip} arrow placement="top">
          <Alert src={alertTriangleIconYellow} alt="alert" />
        </Tooltip>
      </Header>
      <Cardset>
        <Card>
          <Typography variant="h1">
            {staticText.pricing}
            <Addendum variant="subtitle2" component="span">
              {staticText.pricingAddendum}
            </Addendum>
          </Typography>
        </Card>
        <Border />
        <Card>
          <Highlights>
            {staticText.highlights.map(highlight => (
              <Subtitle variant="body1">
                <CheckRounded color="success" fontSize="medium" />
                {highlight.text}
              </Subtitle>
            ))}
          </Highlights>
        </Card>
        <Border />
        <Card>
          <Subtitle variant="body1">{staticText.feature}</Subtitle>
        </Card>
      </Cardset>
    </Container>
  );
};

export default BusinessLegacyCard;
