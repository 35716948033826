const staticText = {
  heading: 'Your Business (Legacy) plan',
  pricing: '$500',
  pricingAddendum: '/Month',
  highlights: [
    { text: '100 vehicles' },
    { text: '$5 for each additional vehicle/month' },
    { text: '500 API calls per vehicle' },
  ],
  feature: 'For product features, refer to the Build plan below',
  tooltip:
    'Please note that our Business plan is now a legacy plan. Although your current billing plan will remain unchanged, we recommend you upgrade to our Scale plan to access more features such as customizable Connect workflows, higher API call limits, and more.',
};

export default staticText;
