/* eslint-disable space-infix-ops */
/* istanbul ignore file */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { array, arrayOf, func } from 'prop-types';

import FilterPill from '../FilterPill/FilterPill';

const FilterPillsList = ({
  filterPills,
  deleteSelectedFilter,
}) => {
  if (filterPills.length === 1) {
    return (
      filterPills.map((filterPill, index) => (
        <Box key={`${filterPill-index}`} display="flex" height="36px" marginBottom="10px">
          <FilterPill
            filterPill={filterPill}
            deleteSelectedFilter={deleteSelectedFilter}
          />
        </Box>
      ))

    );
  }

  if (filterPills.length === 2) {
    return (
      filterPills.map((filterPill, index) => (
        <Box key={`${filterPill-index}`} display="flex" height="36px" marginBottom="10px">
          <FilterPill
            filterPill={filterPill}
            deleteSelectedFilter={deleteSelectedFilter}
          />
          {
            (index === 0 || index + 1 === undefined) &&
            (
              <Typography alignSelf="center" margin="0 10px">
                <b>and</b>
              </Typography>
            )
          }
        </Box>
      ))
    );
  }

  if (filterPills.length === 3) {
    return (
      filterPills.map((filterPill, index) => (
        <Box key={`${filterPill-index}`} display="flex" height="36px" marginBottom="10px">
          <FilterPill
            filterPill={filterPill}
            deleteSelectedFilter={deleteSelectedFilter}
          />
          {
            (index === 0 || index <= 1) &&
            (
              <Typography alignSelf="center" margin="0 10px">
                <b>and</b>
              </Typography>
            )
          }
        </Box>
      ))
    );
  }

  return null;
};

export default FilterPillsList;

FilterPillsList.propTypes = {
  filterPills: arrayOf(array).isRequired,
  deleteSelectedFilter: func.isRequired,
};
