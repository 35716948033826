import React from 'react';
import { number, bool, element, func, object, string } from 'prop-types';
import { FiltersForm, MenuItemWrapper } from '../../../../../../../../../../components';
import { limitMaxEndpoints } from '../../MultiSelectConfig';
import { alphabetize } from '../../../../../../../../utils';

const FilterMenu = ({
  filterKey,
  icon,
  formTitle,
  id,
  minWidth,
  marginTop,
  marginBottom,
  height,
  isFilterActive,
  numSelected,
  menuItemWrapperTitle,
  newFormState,
  getHandleChange,
}) => {
  return (
    <MenuItemWrapper
      title={menuItemWrapperTitle}
      isFilterActive={isFilterActive}
      key={filterKey}
      Icon={icon}
      id={id}
      minWidth={minWidth}
      marginTop={marginTop}
      marginBottom={marginBottom}
      height={height}
      filterKey={filterKey}
    >
      <FiltersForm
        checkboxOptions={newFormState[filterKey]}
        updateCheckboxes={getHandleChange(filterKey)}
        title={filterKey === 'endpoints' ? limitMaxEndpoints : formTitle}
        hideAllButtons
        maxNumSelected={numSelected}
        sortOptions={alphabetize}
        getLabel={option => option}
        showDoneButton
      />
    </MenuItemWrapper>
  );
};

export default FilterMenu;

FilterMenu.propTypes = {
  filterKey: string.isRequired,
  icon: element.isRequired,
  formTitle: string.isRequired,
  id: string.isRequired,
  minWidth: string.isRequired,
  marginTop: string.isRequired,
  marginBottom: string.isRequired,
  height: string.isRequired,
  isFilterActive: bool.isRequired,
  numSelected: number.isRequired,
  menuItemWrapperTitle: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  newFormState: object.isRequired,
  getHandleChange: func.isRequired,
};
