import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

import { OnboardingAccordion } from './components';

import useDeepEqual from '../../../../../../hooks/useDeepEqual';

import stepsConfig from './onboardingChecklistConfig';
import staticText from '../../../../../../localization/Application/Overview/onboardingChecklist';

const ChecklistContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow,

  '.MuiAccordion-root': {
    '.MuiAccordionSummary-root': {
      padding: 0,
    },

    '.MuiAccordionDetails-root': {
      padding: theme.spacing(2, 0, 0, '84px'),

      '& .MuiTypography-copyable': {
        marginBottom: '0px',
      },

      '& .MuiLink-root': {
        marginBottom: 0,

        '& .MuiTypography-root': {
          marginBottom: 0,
        },
      },
    },

    '&.Mui-expanded': {
      margin: 0,
    },
  },
}));

const Success = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 6),
}));

const Cta = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  marginTop: theme.spacing(2),
  lineHeight: '18px',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
}));

export const eventChecker = () => {
  let incompleteStepFound;

  return (acc, step) => {
    // set accordion state to open if it hasn't already been set
    acc.newAccordionState[step.eventName] = acc.newAccordionState[step.eventName] || true;

    // Find the first incomplete step and set flag
    if (!step.eventStatus) {
      incompleteStepFound = true;
    }

    // by the last round, we will know if all steps are complete if this flag is false
    acc.newAllStepsComplete = !incompleteStepFound;
    return acc;
  };
};

const OnboardingChecklist = ({
  actions, onboardingEvents, applicationId,
}) => {
  useEffect(() => {
    actions.startOnboardingPolling();

    return () => {
      actions.stopOnboardingPolling();
    };
  }, []);

  const [accordionState, setAccordionState] = useState({});
  const [allStepsComplete, setAllStepsComplete] = useState(false);

  // update accordion state when new events are checked off
  useEffect(() => {
    const { newAccordionState, newAllStepsComplete } = onboardingEvents.liveModeEvents.reduce(
      eventChecker(),
      {
        newAccordionState: { ...accordionState },
        newAllStepsComplete: allStepsComplete,
      },
    );

    if (newAllStepsComplete) {
      const completedAccordionState = Object.keys(newAccordionState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      setAccordionState(completedAccordionState);
      setAllStepsComplete(newAllStepsComplete);
    } else {
      setAccordionState(newAccordionState);
    }
  }, [useDeepEqual(onboardingEvents.liveModeEvents)]);

  const handleChange = stepName => (event, expanded) => {
    setAccordionState({
      ...accordionState,
      [stepName]: expanded,
    });
  };

  // auxInfo is used to populate the links within each step
  const auxInfo = {
    applicationId,
    sdk: 'connect-javascript',
  };

  // format testModeEvents into an object
  const testModeEvents = _.keyBy(onboardingEvents.testModeEvents, 'eventName');

  return (
    <ChecklistContainer>
      {onboardingEvents.liveModeEvents.map((step) => {
        const testModeEvent = testModeEvents[step.eventName.replace('live', 'test')];
        return stepsConfig[step.eventName] ? (
          <OnboardingAccordion
            key={step.eventName}
            auxInfo={auxInfo}
            expanded={accordionState[step.eventName] || false}
            handleChange={handleChange(step.eventName)}
            step={step}
            stepDetails={stepsConfig[step.eventName]}
            testModeComplete={testModeEvent && testModeEvent.eventStatus}
          />
        ) : null;
      })}
      {allStepsComplete && (
        <Success>
          <Typography>{staticText.completeText}</Typography>
          <Cta
            variant="contained"
            onClick={() => actions.setShowOnboarding(false)}
          >
            {staticText.completeButton}
          </Cta>
        </Success>
      )}
    </ChecklistContainer>
  );
};

export default OnboardingChecklist;

OnboardingChecklist.propTypes = {
  actions: shape({
    startOnboardingPolling: func.isRequired,
    stopOnboardingPolling: func.isRequired,
    setShowOnboarding: func.isRequired,
  }).isRequired,
  onboardingEvents: shape({
    liveModeEvents: arrayOf(shape({
      eventName: string.isRequired,
      eventDisplayName: string.isRequired,
      eventStatus: bool.isRequired,
    }.isRequired)).isRequired,
    testModeEvents: arrayOf(shape({
      eventName: string.isRequired,
      eventDisplayName: string.isRequired,
      eventStatus: bool.isRequired,
    }.isRequired)).isRequired,
  }).isRequired,
  applicationId: string.isRequired,
};
