import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bool, func, shape, string, number } from 'prop-types';
import { Box, Tooltip } from '@mui/material';

import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Dialog, PermissionGate } from '../../../../../../../../components';
import { clockCircle, editPencil, mapCircle, odometerCircle, vehicleInfoCircle } from '../../../../../../../../assets/icons';
import { Card, Icon, Label, Value, EditTripButton } from './styles';
import staticText from '../../../../../../../../localization/Application/Simulator/simulator';
import tripOptions from '../../utils/tripOptions';

const TripStatusCards = ({
  tripActive,
  selectedTripType,
  vehicleState,
  tripClock,
  unitSystem,
  tripDistance,
  handleTripAction,
  vehicle,
  setVehicle,
  autoplay,
}) => {
  const handleEditTrip = () => {
    if (tripActive) {
      handleTripAction('COMPLETE');
    }
    setVehicle({
      ...vehicle,
      simulatedVehicleTripStatus: '',
      simulatedVehicleTripType: '',
      simulatedVehicleTripId: '',
    });
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text: '[simulator] edit trip type',
    });
  };

  const [changeTripDialogOpen, setChangeTripDialogOpen] = useState(false);
  const [distanceDisplayed, setDistanceDisplayed] = useState(tripDistance);

  useEffect(() => {
    if (unitSystem === 'Imperial') {
      setDistanceDisplayed((Number(tripDistance) * 0.6214).toFixed(1));
    } else {
      setDistanceDisplayed(tripDistance);
    }
  }, [unitSystem, tripDistance]);


  const tripName = `${tripOptions.getTripTypeName(selectedTripType)} (${tripOptions.getTripDuration(selectedTripType)})`;

  return (
    <Box display="flex" alignItems="stretch" justifyContent="center">
      <Card>
        {!autoplay && (
          <PermissionGate dashboardPermission="write_simulator">
            <Tooltip
              title="Change trip type"
              arrow
              placement="top"
            >
              <EditTripButton onClick={tripActive ? setChangeTripDialogOpen : handleEditTrip}>
                <img src={editPencil} alt="edit button" />
              </EditTripButton>
            </Tooltip>
          </PermissionGate>
        )}
        <Dialog
          open={changeTripDialogOpen}
          setDialogOpen={setChangeTripDialogOpen}
          heading={staticText.changeTripDialog.heading}
          text={staticText.changeTripDialog.text}
          submitText={staticText.changeTripDialog.submitButtonText}
          handleSubmit={handleEditTrip}
        />
        <Icon>
          <img src={mapCircle} alt="" />
        </Icon>
        <Value>{tripName}</Value>
        <Label variant="caption">{staticText.tripTypeLabel}</Label>
      </Card>
      <Card>
        <Icon>
          <img src={vehicleInfoCircle} alt="" />
        </Icon>
        <Value>{_.capitalize(vehicleState)}</Value>
        <Label variant="caption">{staticText.vehicleStateLabel}</Label>
      </Card>
      <Card>
        <Icon>
          <img src={clockCircle} alt="" />
        </Icon>
        <Value>{`${tripClock.hours} hr  ${tripClock.minutes} min`}</Value>
        <Label variant="caption">{staticText.clockLabel}</Label>
      </Card>
      <Card>
        <Icon>
          <img src={odometerCircle} alt="" />
        </Icon>
        <Value>{`${distanceDisplayed} ${unitSystem === 'Metric' ? 'kilometers' : 'miles'}`}</Value>
        <Label variant="caption">{staticText.distanceLabel}</Label>
      </Card>
    </Box>
  );
};

export default TripStatusCards;

TripStatusCards.propTypes = {
  selectedTripType: string.isRequired,
  tripActive: bool.isRequired,
  vehicleState: string.isRequired,
  tripClock: shape({
    hours: number.isRequired,
    minutes: number.isRequired,
  }).isRequired,
  tripDistance: string.isRequired,
  unitSystem: string.isRequired,
  handleTripAction: func.isRequired,
  vehicle: shape({
    make: string.isRequired,
    model: string.isRequired,
    year: number.isRequired,
    vin: string.isRequired,
    simulatedVehicleTripId: string.isRequired,
    simulatedVehicleTripType: string.isRequired,
    simulatedVehicleTripStatus: string.isRequired,
  }).isRequired,
  setVehicle: func.isRequired,
  autoplay: bool.isRequired,
};
