import React from 'react';
import { any, bool, element, func, oneOfType, string } from 'prop-types';
import { Box, Button, Dialog as MuiDialog, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { infoIcon } from '../../assets/icons';

const Container = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(4),
    minWidth: '350px',
  },
}));

const Dialog = ({
  icon,
  handleSubmit,
  submitText,
  cancelText,
  open,
  setDialogOpen,
  submitButtonColor,
  heading,
  text,
  additionalProps,
}) => {
  const theme = useTheme();
  const handleSubmitClick = () => {
    handleSubmit(additionalProps);
    setDialogOpen(false);
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      {
        label: 'action',
        text: `[simulator] ${submitText}`,
      });
  };
  return (
    <Container open={open}>
      <Box display="flex" marginBottom={2}>
        {icon && icon === 'info' && <img src={infoIcon} alt="info" height="24px" style={{ marginRight: '5px' }} />}
        <Typography variant="h2">{heading}</Typography>
      </Box>
      <Typography>{text}</Typography>
      <Box display="flex" justifyContent="flex-end" marginTop={3}>
        {submitText && handleSubmit &&
          <Button
            id="submit-dialog-btn"
            variant="outlined"
            color={submitButtonColor}
            onClick={handleSubmitClick}
          >{submitText}
          </Button>
        }
        <Button
          id="close-dialog-btn"
          variant="outlined"
          sx={{ marginLeft: theme.spacing(1) }}
          onClick={() => setDialogOpen(false)}
        >{cancelText}
        </Button>
      </Box>
    </Container>
  );
};

export default Dialog;

Dialog.propTypes = {
  handleSubmit: func,
  submitText: string,
  cancelText: string,
  submitButtonColor: string,
  open: bool.isRequired,
  setDialogOpen: func.isRequired,
  heading: string.isRequired,
  text: oneOfType([element, string]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalProps: any,
  icon: string,
};

Dialog.defaultProps = {
  handleSubmit: null,
  submitText: '',
  cancelText: 'Cancel',
  submitButtonColor: 'error',
  additionalProps: null,
  icon: '',
};
