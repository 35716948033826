import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as applicationSelectors } from '../../../../services/applications/reducers';
import { actions, initialState, selectors, types } from './reducers';
import { actions as vehicleActions, types as vehicleTypes, selectors as vehicleSelectors } from './components/VehicleDetails/reducers';
import api from '../../../../services/api/api';
import { Toast } from '../../../../components';
import { errors, messages } from '../../../../localization/Application/VehicleManagement/vehicleManagement';
import formatVehicles from './utils/formatVehicles';
import formatVehicleLogs from './utils/formatVehicleLogs';

export function* fetchAllCompatibleVehicles() {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchAllCompatibleVehicles,
      applicationId,
    );
    const formattedVehicles = formatVehicles(data);
    yield put(actions.fetchAllCompatibleVehiclesSuccess(formattedVehicles));
  } catch (error) {
    yield put(actions.fetchAllCompatibleVehiclesFailure(error));
  }
}

export function* fetchConnectedVehicles(action) {
  try {
    const { filterValues, newPaginationModel } = action.payload;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchConnectedVehicles,
      applicationId,
      filterValues,
    );

    const results = {
      pageInfo: {
        rows: data.rows,
        columns: data.cols,
        rowCount: data.paging.count,
      },
    };

    const { cursor, mode, ...filters } = filterValues;
    const noFilters = Object.keys(filters).length === 0;
    const pageZero = newPaginationModel.page === 0;

    const shouldConnectVehicle = noFilters && pageZero && data.rows.length === 0;
    yield put(actions.updateProvideConnectCta(shouldConnectVehicle));

    // Reset countDifference and cursorMap whenever we fetch page 0
    if (pageZero) {
      yield put(actions.updateCursorMap(initialState.pageToNextCursorMap));
      yield put(actions.updateCountDifference(initialState.countDifference));
    }

    yield put(actions.fetchConnectedVehiclesSuccess(results));

    const cursorMap = yield select(selectors.getPageToNextCursorMap);
    yield put(actions.updateCursorMap({
      ...cursorMap,
      [newPaginationModel.page]: data.paging.cursor,
    }));
    yield put(actions.updatePaginationModel(newPaginationModel));
  } catch (error) {
    yield put(actions.fetchConnectedVehiclesFailure(error));
  }
}

export function* disconnectVehicle(action) {
  try {
    const { payload: vehicleId } = action;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { data } = yield call(
      api.disconnectVehicle,
      applicationId,
      vehicleId,
    );
    // server sends success code when no vehicle is found, client will treat that as an error
    if (data.message === 'Vehicle not found') {
      throw new Error(data.message);
    }

    yield call(Toast, messages.disconnectVehicleSuccess, 'success');
    const countDifference = yield select(selectors.getCountDifference);
    yield put(actions.updateCountDifference(countDifference + 1));
    yield put(actions.disconnectVehicleSuccess(data.message));
  } catch (error) {
    yield call(Toast, messages.disconnectVehicleFailure, 'warn');
    yield put(actions.disconnectVehicleFailure(error));
  }
}

export function* fetchVehicleInfo(action) {
  const { payload: vehicleId } = action;
  const applicationId = yield select(applicationSelectors.getSelectedApplication);
  // currently only available to 'live' vehicles, server will default to live mode
  const filterValues = { vehicle_id: vehicleId };

  try {
    const { data } = yield call(
      api.fetchConnectedVehicles,
      applicationId,
      filterValues,
    );
    const vehicleData = data.rows.find(row => row.vehicleId === vehicleId);
    if (!vehicleData) {
      throw new Error(errors.vehicleNotFound);
    }
    const { vehicle, ...info } = vehicleData;
    const vehicleInfo = { ...vehicle, ...info };
    yield put(vehicleActions.fetchVehicleInfoSuccess(vehicleInfo));
  } catch (error) {
    yield put(vehicleActions.fetchVehicleInfoFailure(error));
  }
}

export function* fetchVehicleLogs(action) {
  try {
    const {
      meta: vehicleId,
      payload: { filterValues, newPaginationModel },
    } = action;
    const storedRowCount = yield select(vehicleSelectors.getRowCount);
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchVehicleLogs,
      applicationId,
      vehicleId,
      filterValues,
    );
    const updatedData = {
      ...data,
      totalCount: data.totalCount || storedRowCount,
    };
    const formattedLogs = formatVehicleLogs(updatedData, filterValues.offset);

    yield put(vehicleActions.fetchVehicleLogsSuccess(formattedLogs));
    yield put(vehicleActions.updatePaginationModel(newPaginationModel));
  } catch (error) {
    yield put(vehicleActions.fetchVehicleLogsFailure(error));
  }
}

export function* fetchVehicleOverview(action) {
  try {
    const { payload: vehicleId } = action;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const applications = yield select(applicationSelectors.getApplications);
    const { organizationId } = applications[applicationId];
    const { data } = yield call(
      api.fetchVehicleOverview,
      applicationId,
      vehicleId,
      organizationId,
    );

    yield put(vehicleActions.fetchVehicleOverviewSuccess(data));
  } catch (error) {
    yield put(vehicleActions.fetchVehicleOverviewFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST, fetchAllCompatibleVehicles);
  yield takeLatest(types.FETCH_CONNECTED_VEHICLES_REQUEST, fetchConnectedVehicles);
  yield takeLatest(types.DISCONNECT_VEHICLE_REQUEST, disconnectVehicle);
  yield takeLatest(vehicleTypes.FETCH_VEHICLE_INFO_REQUEST, fetchVehicleInfo);
  yield takeLatest(vehicleTypes.FETCH_VEHICLE_LOGS_REQUEST, fetchVehicleLogs);
  yield takeLatest(vehicleTypes.FETCH_VEHICLE_OVERVIEW_REQUEST, fetchVehicleOverview);
}
