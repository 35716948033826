import React, { useEffect } from 'react';
import { bool, shape, string } from 'prop-types';
import { reportToSegment, types } from '@smartcar/morse';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import applicationsProps from '../../../../types/applications';

import {
  BarChart,
  DoughnutChart,
  LineChart,
} from '../../../../components';
import { ApplicationHeader } from '../../components';
import {
  ChartWrapper,
  DateFilter,
  OnboardingChecklist,
  UsageWidget,
} from './components';
import {
  GraphsContainer,
  OverviewContainer,
  PanelContent,
  WidgetsContainer,
} from './styles';

import { staticText } from '../../../../localization/Application/Overview/overview';
import {
  getDefaultUsageOptions,
  getMakesData,
  getDefaultMakesOptions,
  getUsageChartData,
  getResponseStatusData,
  getResponseStatusOptions,
} from './utils/chartConfigurations';
import { formatChartOptions } from './utils/utils';
import getFilterConfiguration from './utils/getFilterConfiguration';
import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import dateUtils from './utils/dateUtils';
import { organizationRole } from '../Members/utils/roles';

/* istanbul ignore next */
const Overview = ({
  actions: {
    fetchActiveVehiclesTimeSeries,
    fetchApiRequestTimeSeries,
    fetchMakesSummary,
    fetchResponseStatusSummary,
    hideSecret,
    updateStatusCodesAndMakesFromOverviewChart,
    setFilter,
    resetFetching,
  },
  applications,
  errors,
  filter,
  isFetchingOverview,
  match,
  metrics,
  organization,
  showOnboarding,
  selectedChartOption,
  history,
}) => {
  const {
    apiRequestTimeSeries,
    activeVehiclesTimeSeries,
    makesSummary,
    responseStatusSummary,
  } = metrics;
  const { applicationId } = match.params;
  const { createdAt } = applications[applicationId];

  const refreshChartData = () => {
    fetchApiRequestTimeSeries(applicationId);
    fetchActiveVehiclesTimeSeries(applicationId);
    fetchResponseStatusSummary(applicationId);
    fetchMakesSummary(applicationId);
  };


  const appFeatureSetId = applications[applicationId].featureSetId;
  const logDays = gatedFeatureData(FEATURES.LOG_DAYS, appFeatureSetId);
  const minDate = dateUtils.getStartOfDayUtc(dateUtils.minDate(logDays || 1));

  let showOnboardingChecklist = showOnboarding;

  if (organization.organizationRole !== organizationRole.FULL_ACCESS) {
    showOnboardingChecklist = false;
  }

  const theme = useTheme();

  useEffect(() => {
    reportToSegment(types.PAGE, 'Overview');

    return () => {
      hideSecret();
      resetFetching();
    };
  }, []);

  useEffect(() => {
    if (!showOnboardingChecklist) {
      const newFilter = getFilterConfiguration('month');
      setFilter(newFilter, 'timeframe');

      refreshChartData();
    }
  }, [showOnboardingChecklist]);

  if (selectedChartOption) {
    useEffect(() => {
      if (
        selectedChartOption.makes || selectedChartOption.statusCodes) {
        history.push(`/apps/${applicationId}/logs`);
      }
    }, [selectedChartOption.statusCodes, selectedChartOption.makes]);
  }

  // api requests over time
  const apiRequestsData = getUsageChartData(
    'LINE',
    'requests',
    apiRequestTimeSeries,
  );
  const apiRequestsOptions = formatChartOptions(
    apiRequestsData,
    getDefaultUsageOptions(apiRequestTimeSeries.timeframe, 'API requests'),
  );

  // active vehicles
  const activeVehiclesData = getUsageChartData(
    'BAR',
    'active vehicles',
    activeVehiclesTimeSeries,
  );
  const activeVehiclesOptions = formatChartOptions(
    activeVehiclesData,
    getDefaultUsageOptions(
      activeVehiclesTimeSeries.timeframe,
      'active vehicles',
    ),
  );

  // response statuses
  const responseStatusData = getResponseStatusData(responseStatusSummary);
  const responseStatusOptions = getResponseStatusOptions(
    responseStatusSummary.otherData,
    updateStatusCodesAndMakesFromOverviewChart,
  );

  // all vehicles by make
  const allVehiclesByMakeData = getMakesData(makesSummary);
  const allVehiclesByMakeOptions = getDefaultMakesOptions(
    makesSummary.otherData,
    updateStatusCodesAndMakesFromOverviewChart,
  );

  // get makes filters for logs pages
  useEffect(() => {
    const logsFilters = {
      makes: {},
      statusCodes: {},
    };
    if (makesSummary.makeFilterConfiguration) {
      logsFilters.makes = makesSummary.makeFilterConfiguration;
    }
    if (responseStatusSummary.statusCodeConfiguration) {
      logsFilters.statusCodes = responseStatusSummary.statusCodeConfiguration;
    }
  }, [
    makesSummary.makeFilterConfiguration,
    responseStatusSummary.statusCodeConfiguration,
  ]);

  const widgetsConfiguration = staticText.getWidgetsConfiguration(metrics);

  return (
    <Box sx={{ maxWidth: showOnboardingChecklist ? theme.width.content : '1600px', minWidth: '900px' }}>
      <ApplicationHeader heading={staticText.heading} />
      {showOnboardingChecklist ? (
        <OnboardingChecklist />
      ) : (
        <React.Fragment>
          <DateFilter
            createdAt={createdAt}
            minDate={minDate}
            refreshChartData={refreshChartData}
            setFilter={setFilter}
            timeframe={filter.timeframe}
          />
          <OverviewContainer>
            <PanelContent>
              <WidgetsContainer id="widgets-container">
                {widgetsConfiguration.map(widget => (
                  <UsageWidget
                    {...widget}
                    key={widget.title}
                    isLoading={isFetchingOverview[widget.sagaKey]}
                    isErrored={!!errors[widget.sagaKey]}
                  />
                    ))}
              </WidgetsContainer>
              <GraphsContainer id="graphs-container">
                <ChartWrapper
                  chartTitle={staticText.chartTitles.apiRequests}
                  isLoading={isFetchingOverview.apiRequestTimeSeries}
                  isErrored={!!errors.apiRequestTimeSeries}
                >
                  <LineChart
                    data={apiRequestsData}
                    options={apiRequestsOptions}
                  />
                </ChartWrapper>
                <ChartWrapper
                  chartTitle={staticText.chartTitles.statusCodes}
                  isLoading={isFetchingOverview.responseStatusSummary}
                  isErrored={!!errors.responseStatusSummary}
                >
                  <DoughnutChart
                    data={responseStatusData}
                    options={responseStatusOptions}
                  />
                </ChartWrapper>
                <ChartWrapper
                  chartTitle={staticText.chartTitles.activeVehicles}
                  isLoading={isFetchingOverview.activeVehiclesTimeSeries}
                  isErrored={!!errors.activeVehiclesTimeSeries}
                >
                  <BarChart
                    data={activeVehiclesData}
                    options={activeVehiclesOptions}
                  />
                </ChartWrapper>
                <ChartWrapper
                  chartTitle={staticText.chartTitles.makes}
                  isLoading={isFetchingOverview.makesSummary}
                  isErrored={!!errors.makesSummary}
                >
                  <BarChart
                    data={allVehiclesByMakeData}
                    options={allVehiclesByMakeOptions}
                  />
                </ChartWrapper>
              </GraphsContainer>
            </PanelContent>
          </OverviewContainer>
        </React.Fragment>
      )}
    </Box>
  );
};

export default Overview;

Overview.propTypes = {
  showOnboarding: bool.isRequired,
  match: shape({
    params: shape({
      applicationId: string.isRequired,
    }).isRequired,
  }).isRequired,
  ...applicationsProps,
  organization: shape({
    id: string.isRequired,
    organizationRole: string.isRequired,
  }).isRequired,
};
