import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import OnboardingChecklist from './OnboardingChecklist';
import { actions } from '../../../../../../services/onboarding/reducers';

function mapStateToProps(state) {
  return {
    ...state.onboarding,
    applicationId: state.applications.selectedApplication,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    startOnboardingPolling,
    stopOnboardingPolling,
    setShowOnboarding,
  } = actions;
  return {
    actions: bindActionCreators({
      startOnboardingPolling,
      stopOnboardingPolling,
      setShowOnboarding,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingChecklist);
