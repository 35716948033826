import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isSameDay } from 'date-fns';
import { reportToSegment, types as segmentTypes } from '@smartcar/morse';

import api from '../api/api';
import { types, actions, selectors } from './reducers';
import { actions as errorActions } from '../errorReporter/reducers';
import { selectors as orgSelectors } from '../organizations/reducers';
import { fetchOrganizations } from '../organizations/sagas';
import { getDeveloper } from '../auth/sagas';
import { isInTrial } from '../../scenes/Application/components/Billing/utils/textFormatters';
import staticText from '../../localization/Application/Billing/billing';

export function* fetchBillingInfo() {
  try {
    const organizationId = yield select(orgSelectors.getSelectedOrganization);
    const organization = yield select(orgSelectors.getCurrentOrganization);
    const orgFeatureSetId = organization.featureSetId;
    const { data } = yield call(api.fetchBillingInfo, organizationId, orgFeatureSetId);
    yield put(actions.fetchBillingInfoSuccess(data));
  } catch (error) {
    yield put(actions.fetchBillingInfoFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* initializeBilling() {
  try {
    let showFreeTrialModal = false;
    const currentOrganization = yield select(orgSelectors.getCurrentOrganization);
    if (currentOrganization.rolePermissions.includes('read_billing')) {
      yield call(fetchBillingInfo);

      const currentPlan = yield select(selectors.getCurrentPlan);
      const trialActive = isInTrial(currentPlan);

      if (trialActive) {
        // show the free trial modal only if it's the first day of the trial and the trial is active
        const billingPeriod = yield select(selectors.getBillingPeriod);
        showFreeTrialModal =
          isSameDay(new Date(billingPeriod.billingPeriodStartDate), new Date(Date.now())) &&
          trialActive;
      }
    }
    // If we need to fetch partial billing info for other organization roles, we can do it here
    yield put(actions.initializeBillingSuccess(showFreeTrialModal));
  } catch (error) {
    yield put(actions.initializeBillingFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* updateBillingInfo(action) {
  try {
    const organizationId = yield select(orgSelectors.getSelectedOrganization);
    const currentPlan = yield select(selectors.getCurrentPlan);

    if (action.payload === staticText.buildPlanName) {
      // Able to remove Business when there are no more Business (legacy) plans
      if (
        !(
          currentPlan === staticText.freePlanName ||
          currentPlan === staticText.buildPlanTrialName ||
          currentPlan === staticText.scalePlanName ||
          currentPlan === staticText.businessPlanName
        )
      ) {
        throw new Error(
          'Unable to subscribe to Build due to current plan developer is subscribed to',
        );
      }
    } else if (action.payload === staticText.freePlanName) {
      if (
        !(
          currentPlan === staticText.buildPlanName ||
          currentPlan === staticText.buildPlanTrialName ||
          currentPlan === staticText.scalePlanName ||
          currentPlan === staticText.businessPlanName
        )
      ) {
        throw new Error(
          'Unable to downgrade due to current plan developer is subscribed to',
        );
      }
    }

    const updateBillingPayload = {
      currentPlan: currentPlan.toLowerCase(),
      planName: action.payload && action.payload.toLowerCase(),
      stripeCheckoutSessionId: action.meta,
    };

    yield call(
      api.updateBillingInfo,
      organizationId,
      updateBillingPayload,
    );
    yield call(
      reportToSegment,
      segmentTypes.GROUP,
      organizationId,
      {
        plan: updateBillingPayload.planName,
      },
    );


    yield call(getDeveloper);
    // the organization's feature set id may have been updated, so we fetch orgs again
    yield call(fetchOrganizations);

    const { featureSetId: orgFeatureSetId } = yield select(orgSelectors.getCurrentOrganization);
    const { data } = yield call(api.fetchBillingInfo, organizationId, orgFeatureSetId);

    yield put(actions.updateBillingInfoSuccess(data));

    // Show free trial modal if user upgraded to trial
    if (action.payload === staticText.buildPlanName) {
      const newPlan = yield select(selectors.getCurrentPlan);
      const trialActive = isInTrial(newPlan);
      yield put(actions.initializeBillingSuccess(trialActive));
    }
  } catch (error) {
    yield put(actions.updateBillingInfoFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(types.FETCH_BILLING_INFO, fetchBillingInfo);
  yield takeEvery(types.INITIALIZE_BILLING, initializeBilling);
  yield takeEvery(types.UPDATE_BILLING_INFO, updateBillingInfo);
}
