import React, { useEffect } from 'react';
import { bool, element, func, object, shape, string } from 'prop-types';

import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { VehiclesList } from './components';
import { SimulatorHeader } from '../shared';
import { EmptyList, Feedback, InlineRoute, ResourceCards } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/Simulator/home';
import FEATURES from '../../../../../../services/featureGate/features';
import { isAutoCreatedVehicle } from '../../utils/utils';

const SimulatorHome = ({
  actions: {
    setSelectedVehicle,
    setSelectedRegion,
    fetchVehicleTrip,
    deleteVehicle,
  },
  applicationId,
  simulatedVehicles,
  upgradeMessage, // if present, this feature is gated
  canWriteBilling,
}) => {
  const hasVehicles = Object.keys(simulatedVehicles).length > 0;
  const hasAutoCreatedVehicle =
    Object.keys(simulatedVehicles).find(vin => isAutoCreatedVehicle(vin));

  // if the user has downgraded back to free from a paid plan
  // we will update the messaging to push upgrading to regain access to their
  // simulated vehicles (including if they still have access to an auto-created vehicle)
  let upgradeAgainMessage;
  if (upgradeMessage) {
    const message = canWriteBilling
      ? <InlineRoute text={staticText.featureGated.regainAccessDescription} segmentLabel={`upsell ${FEATURES.VEHICLE_SIMULATOR}`} />
      : staticText.featureGated.contactOwnersToRegainAccess;
    upgradeAgainMessage = <Feedback type="info" message={message} />;
  }

  // if the use has an auto-created vehicle, we will update the messaging
  // to push upgrading to unlock more features, and the vehicle will remain
  // accessible even if they have downgraded
  let unlockFeaturesMessage;
  if (upgradeMessage) {
    const message = canWriteBilling
      ? <InlineRoute text={staticText.featureGated.unlockMoreFeatures} segmentLabel={`upsell ${FEATURES.VEHICLE_SIMULATOR}`} />
      : staticText.featureGated.contactUnlockMoreFeatures;
    unlockFeaturesMessage = <Feedback type="info" message={message} />;
  }

  const handleCreateVehicleClick = () => {
    reportToSegment(
      types.TRACK,
      eventNames.linkClicked,
      {
        section: 'header',
        style: 'cta primary',
        label: '[simulator] create new vehicle',
        text: staticText.newVehicle,
        path: `/apps/${applicationId}/simulator/create-vehicle`,
      });
  };

  useEffect(() => {
    reportToSegment(types.PAGE, 'Simulator - Home');
  }, []);

  return (
    <div id="simulator-home">
      <SimulatorHeader
        heading={staticText.heading}
        homeLink={false}
        cta={{
          text: staticText.newVehicle,
          path: `/apps/${applicationId}/simulator/create-vehicle`,
          onclick: handleCreateVehicleClick,
          plusIcon: true,
        }}
      />
      {hasVehicles ?
        <VehiclesList
          applicationId={applicationId}
          fetchVehicleTrip={fetchVehicleTrip}
          setSelectedVehicle={setSelectedVehicle}
          setSelectedRegion={setSelectedRegion}
          handleCreateVehicleClick={handleCreateVehicleClick}
          deleteVehicle={deleteVehicle}
          vehicles={simulatedVehicles}
          upgradeMessage={
            (Object.keys(simulatedVehicles).length === 1 && hasAutoCreatedVehicle) ?
              unlockFeaturesMessage :
              upgradeAgainMessage
          }
        /> :
        <EmptyList
          heading={staticText.emptyState.heading}
          details={staticText.emptyState.details}
          cta={{
            text: staticText.vehiclesList.createFirstVehicle,
            path: `/apps/${applicationId}/simulator/create-vehicle`,
            onClick: handleCreateVehicleClick,
          }}
          upgradeMessage={upgradeMessage}
          dashboardPermission="write_simulator"
        />
      }
      <ResourceCards cards={staticText.resourceCards} />
    </div>
  );
};

export default SimulatorHome;

SimulatorHome.propTypes = {
  actions: shape({
    setSelectedVehicle: func.isRequired,
    setSelectedRegion: func.isRequired,
    fetchVehicleTrip: func.isRequired,
    deleteVehicle: func.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  simulatedVehicles: object.isRequired,
  upgradeMessage: element,
  canWriteBilling: bool.isRequired,
};

SimulatorHome.defaultProps = {
  upgradeMessage: null,
};
