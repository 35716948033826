import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import _ from 'lodash';
import { Box } from '@mui/material';

import { Checkbox, Chip } from '../../../../../../../components/';

const ResponseData = ({
  inputValues, children, inputName, handleChange,
}) => {
  const handleResponseChange = (event) => {
    const {
      target: {
        name, checked,
      },
    } = event;

    let values;
    if (!inputValues.length) {
      values = [];
    } else {
      values = [...inputValues];
    }

    if (checked) {
      values.push(name);
      handleChange({ key: inputName, value: values });
    } else {
      _.pull(values, name);
      handleChange({ key: inputName, value: values });
    }
  };

  return (
    <div>
      {children.map(({ text, path, name }) => (
        <Box key={text} mb={3} display="flex" alignItems="center">
          <Checkbox
            wrapperClassName="flex-vertical-center m-r-med"
            inputName={name}
            key={text}
            text={text}
            checked={inputValues.includes(name)}
            handleChange={handleResponseChange}
          />
          {path && <Chip text={path} />}
        </Box>
      ))}
    </div>
  );
};

export default ResponseData;

ResponseData.propTypes = {
  children: arrayOf(shape({
    text: string,
    path: string,
  })).isRequired,
  inputName: string.isRequired,
  handleChange: func.isRequired,
  inputValues: arrayOf(string),
};

ResponseData.defaultProps = {
  inputValues: [],
};
