import React from 'react';
import { bool, func, oneOfType, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';

import { ArrowLink, Credentials } from '../../../../../../../../components';
import { checkmarkIconGreen, checkmarkIconGray } from '../../../../../../../../assets/icons';

import staticText from '../../../../../../../../localization/Application/Overview/onboardingChecklist';

export const Image = styled('img')(({ theme }) => ({
  margin: theme.spacing(0, 2),
}));

// eslint-disable-next-line react/prop-types
export const actionGenerator = ({
  text, path, pathCreator, style,
}, auxInfo, theme) => {
  switch (style) {
    case 'credentials':
      return (
        <Credentials hasHeading={false} />
      );
    case 'cta':
      return (
        <Link to={path || pathCreator(auxInfo)}>
          <Button
            variant="contained"
            component="span"
            sx={{ padding: theme.spacing(1, 2) }}
          >
            {text}
          </Button>
        </Link>
      );
    case 'arrow':
    default:
      return <ArrowLink link={path || pathCreator(auxInfo)} text={text} icon="launch" />;
  }
};

const OnboardingAccordion = (props) => {
  const {
    auxInfo, expanded, handleChange, step, stepDetails, testModeComplete,
  } = props;
  const theme = useTheme();

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ChevronRight />}
      >
        {step.eventStatus
          ? <Image src={checkmarkIconGreen} alt="Step complete" />
          : <Image src={checkmarkIconGray} alt="Step incomplete" />
        }
        <Typography variant="h3">{step.eventDisplayName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{stepDetails.text}</Typography>
          {testModeComplete && <Typography variant="bold" component="p">{staticText.testMode}</Typography>}
        </div>
        {stepDetails.action && actionGenerator(stepDetails.action, auxInfo, theme)}
      </AccordionDetails>
    </Accordion>
  );
};

export default OnboardingAccordion;

OnboardingAccordion.propTypes = {
  auxInfo: shape({
    applicationId: string.isRequired,
    sdk: string,
  }).isRequired,
  expanded: bool.isRequired,
  handleChange: func.isRequired,
  step: shape({
    eventDisplayName: string.isRequired,
    eventStatus: bool.isRequired,
  }).isRequired,
  stepDetails: shape({
    text: string.isRequired,
    action: shape({
      path: oneOfType([string, func]),
      text: string,
      style: string.isRequired,
    }),
  }).isRequired,
  testModeComplete: bool,
};

OnboardingAccordion.defaultProps = {
  testModeComplete: false,
};
