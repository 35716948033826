import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BrandManagement from './BrandManagement';
import { actions } from '../../reducers';

function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    updateSelectedMakes,
    updateConnectConfigRequest,
    updatePreviewSettings,
    updateAppliedFilters,
    fetchBrandManagementFilterOptions,
    updateAvailableMakesAfterAppliedFilter,
    fetchApplicationMakesRequest,
    fetchConnectConfigRequest,
    disableAllBrands,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        updateSelectedMakes,
        updateConnectConfigRequest,
        updatePreviewSettings,
        updateAppliedFilters,
        fetchBrandManagementFilterOptions,
        updateAvailableMakesAfterAppliedFilter,
        fetchApplicationMakesRequest,
        fetchConnectConfigRequest,
        disableAllBrands,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandManagement);
