import React from 'react';
import { string } from 'prop-types';

import './InlineLinks.scss';
import ArrowLink from '../ArrowLink';

const InlineLinks = ({ text, icon }) => {
  const splitOnLinks = text.split('|+|');
  // If there is no link, return the text
  if (splitOnLinks.length === 1) {
    return text;
  }

  return splitOnLinks.map((section) => {
    if (!section) return null;
    if (section.includes('@@')) {
      const [linkText, linkUrl] = section.split('@@');
      return (
        <ArrowLink
          key={linkText}
          link={linkUrl}
          text={linkText}
          icon={icon}
        />
      );
    }
    return <span key={section}>{section}</span>;
  });
};

export default InlineLinks;

InlineLinks.propTypes = {
  text: string.isRequired,
  icon: string,
};
