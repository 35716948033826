import { Box, styled } from '@mui/material';

export const ImageUploadWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'hasLogo',
})(({ theme, hasLogo }) => ({
  display: 'flex',
  alignItems: 'center',

  '& .logo-image-container': {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    position: 'relative',

    width: '100px',
    height: '100px',
    backgroundColor: hasLogo ? theme.palette.common.white : theme.palette.grey[200],
    borderRadius: '8%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[200]}`,

    '& img': {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
    },
  },

  '& #image-upload-input': {
    opacity: '0',
    width: '0.1px',
    height: '0.1px',
    position: 'absolute',
  },
}));

export const UploadButton = styled(Box, {
  shouldForwardProp: prop => prop !== 'disabled',
})(({ disabled, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 8px',
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  backgroundColor: theme.palette.surface,
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.border.radius,

  '&:hover': {
    color: theme.palette.primary.secondary,
    border: `1px solid ${theme.palette.primary.secondary}`,
  },

  ...(disabled && {
    color: theme.palette.text.disabled,
    border: `1px solid ${theme.palette.text.disabled}`,

    '&:hover': {
      cursor: 'default',
      color: theme.palette.text.disabled,
      border: `1px solid ${theme.palette.text.disabled}`,
    },
  }),
}));
