import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Countries from './Countries';
import { actions } from '../../reducers';

function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    updateSelectedCountries,
    updateConnectConfigRequest,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        updateSelectedCountries,
        updateConnectConfigRequest,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
