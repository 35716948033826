import React from 'react';
import { bool, func, string } from 'prop-types';
import { Box, Typography, FormControl, RadioGroup, Radio, Tooltip, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import PermissionGate from '../../../../../../../../components/PermissionGate';

import staticText from '../../../../../../../../localization/Application/connect-config';
import { RadioButtonLabel } from '../../../../../../../../components/Form/components/RadioGroup/RadioGroup';

const SortOrder = ({ selectedSortOrder, updatePreviewSettings, disabled }) => {
  const theme = useTheme();

  const displayOrderOptions = staticText.brandManagement.displayedOrderRadio;

  /* istabul ignore next */
  const updateSortOrder = (event) => {
    const { value } = event.target;
    updatePreviewSettings({ selectedSortOrder: value });
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      marginY={3}
      flexDirection="column"
    >
      <Typography marginY={3} variant="h3">{staticText.brandManagement.displayedOrder}</Typography>
      <FormControl>
        <RadioGroup
          row
          onChange={updateSortOrder}
        >
          {Object.keys(displayOrderOptions).map((option) => {
            const displayOptionName = displayOrderOptions[option].label;
            const displayTooltip = displayOrderOptions[option].tooltip;
            return (
              <PermissionGate
                key={option}
                dashboardPermission="write_connect_configuration"
                disabled
              >
                <RadioButtonLabel
                  key={option}
                  value={option}
                  label={
                    <Box display="flex" alignItems="center">
                      {displayOptionName}
                      <Tooltip
                        placement="top"
                        title={displayTooltip}
                      >
                        <InfoOutlined
                          htmlColor={theme.palette.grey[600]}
                        />
                      </Tooltip>
                    </Box>
                  }
                  control={<Radio />}
                  disabled={disabled}
                  checked={(!selectedSortOrder && option === 'popularity') ||
                  (selectedSortOrder === option)}
                />
              </PermissionGate>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default SortOrder;


SortOrder.propTypes = {
  selectedSortOrder: string.isRequired,
  updatePreviewSettings: func.isRequired,
  disabled: bool.isRequired,
};
