/* eslint-disable no-restricted-syntax */
/* istanbul ignore file */
function filterSkills(skill, filters) {
  const availableBrandsAfterAppliedFilter = [];

  if (filters.length === 1) {
    // Helper function to check if any item from 'items' is in 'arr'
    const matchesAny = (arr, items) => items.some(item => arr.includes(item));

    // Iterate over each brand in the brand object
    for (const [key, value] of Object.entries(skill)) {
      // Check if either engineType or endpoint has any of the elements from filters
      if (matchesAny(value.engineType, filters[0]) || matchesAny(value.endpoint, filters[0])) {
        availableBrandsAfterAppliedFilter.push(key);
      }
    }
  } else {
    // Iterate over each brand in the brand object
    for (const [key, value] of Object.entries(skill)) {
      let matches = true;

      // Check each clause in filters
      for (const clause of filters) {
        // eslint-disable-next-line no-continue
        if (!Array.isArray(clause)) continue;

        // Each clause should be treated as an 'or' condition within itself
        let orMatch = false;

        for (const condition of clause) {
          // Check if the condition matches either engineType or endpoint
          if (value.engineType.includes(condition) || value.endpoint.includes(condition)) {
            orMatch = true;
            break;
          }
        }

        // If none of the conditions in the clause matched, the 'and' condition fails
        if (!orMatch) {
          matches = false;
          break;
        }
      }

      // If all 'and' conditions matched, add the key to the result
      if (matches) {
        availableBrandsAfterAppliedFilter.push(key);
      }
    }
  }

  return availableBrandsAfterAppliedFilter;
}

const determineFilteredBrands = (capabilities, selectedFilterValues, currentFilters) => {
  const engineTypeSelectedFilters = [];
  const endpointSelectedFilters = [];

  Object.entries(selectedFilterValues.engineType).forEach(([element, value]) => {
    if (value) engineTypeSelectedFilters.push(element);
  });

  Object.entries(selectedFilterValues.endpoints).forEach(([element, value]) => {
    if (value) endpointSelectedFilters.push(element);
  });

  // updatesAppliedFilters needed for determining makes
  const updatedFilter = [...currentFilters];
  if (engineTypeSelectedFilters.length > 0) {
    updatedFilter.push(engineTypeSelectedFilters);
  }
  if (endpointSelectedFilters.length > 0) {
    updatedFilter.push(endpointSelectedFilters);
  }

  const availableBrandsAfterAppliedFilter = filterSkills(capabilities, updatedFilter);

  // returns the following:
  // - disableBrands covers an important use case and disables all options if
  //   no brand fit the applied filters. Without this, all brands would be selectable
  //   regardless of the filter
  // - available brands after applying filter to brands list
  // - the selected filters which are used to create the filter pills
  return {
    disableBrands: availableBrandsAfterAppliedFilter.length === 0,
    availableBrandsAfterAppliedFilter,
    engineTypeSelectedFilters,
    endpointSelectedFilters,
  };
};


export const determineFilteredBrandsFromConnectConfig = (capabilities, selectedFilterValues) => {
  return filterSkills(capabilities, selectedFilterValues);
};

export default determineFilteredBrands;
