const sdkDocsMap = {
  'api-java': 'backend',
  'api-node.js': 'backend',
  'api-python': 'backend',
  'connect-android': 'android',
  'connect-ios': 'ios',
  'connect-javascript': 'react',
};

const config = {
  create_application: {
    text: 'You’ve already completed this step.',
    action: {
      text: 'Change app settings',
      pathCreator: ({ applicationId }) => `/apps/${applicationId}/configuration`,
      style: 'cta',
    },
  },
  generate_client_credentials: {
    text: 'Let your users link their cars to your app with our authorization flow Smartcar Connect.',
    action: {
      style: 'credentials',
    },
  },
  launch_auth_flow_in_live_mode: {
    text: 'Check out our starter application repository to launch Smartcar Connect.',
    action: {
      text: 'Get started',
      path: 'https://github.com/smartcar/starter-app-react-node',
      style: 'arrow',
    },
  },
  retrieve_access_token_in_live_mode: {
    text: 'Exchange your authorization code for an access token.',
    action: {
      text: 'Read the tutorial',
      pathCreator: ({ sdk }) => `https://smartcar.com/docs/getting-started/tutorials/${sdkDocsMap[sdk] || 'react'}#getting-your-first-access-token`,
      style: 'arrow',

    },
  },
  make_first_api_call_in_live_mode: {
    text: 'Retrieve the list of vehicle IDs that your users have linked to your app.\nMake your first API request to one of those cars.',
    action: {
      text: 'Read the tutorial',
      pathCreator: ({ sdk }) => `https://smartcar.com/docs/getting-started/tutorials/${sdkDocsMap[sdk] || 'react'}#getting-data-from-a-vehicle`,
      style: 'arrow',
    },
  },
};

export default config;
