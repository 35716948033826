import { LicenseInfo } from '@mui/x-data-grid-pro';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import { shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { PrivateRoute, PublicRoute } from './components/AuthenticatedRoutes';
import {
  Application,
  Authentication,
  GoogleOAuth,
  Initialization,
  Join,
  Setup,
  VerifyEmail,
} from './scenes';
import { MfaEnroll, MfaLogin } from './scenes/Authentication/components';
import sessionUtils from './services/auth/utils/sessionUtils';

LicenseInfo.setLicenseKey(process.env.MUI_X_PRO_LICENSE_KEY);

const App = ({
  appId, appOrgId, session, organization,
}) => {
  const isAuthenticated = sessionUtils.isAuthenticated(session);
  const { featureSetId: orgFeatureSetId, id: organizationId } = organization;

  const ldClient = useLDClient();
  useEffect(() => {
    if (ldClient && isAuthenticated) {
      ldClient.identify({
        kind: 'multi',
        user: {
          key: session.userContext.dashboardUserId,
          name: `${session.userContext.firstName} ${session.userContext.lastName}`,
          email: session.userContext.email,
        },
        // conditionally identify because a user may not have an org or app yet
        ...(appId && appOrgId ? {
          app: {
            key: appId,
            organizationId: appOrgId,
          },
        } : {}),
        ...(organizationId && orgFeatureSetId ? {
          team: {
            key: organizationId,
            featureSetId: orgFeatureSetId,
            createdAt: moment(organization.createdAt).valueOf(),
          },
        } : {}),
      });
    }
  }, [isAuthenticated, appId, organizationId]);

  return (
    <div>
      <ToastContainer transition={Slide} />
      <Switch>
        <Route
          component={Join}
          path="/join"
        />
        <PublicRoute
          component={GoogleOAuth}
          isAuthenticated={isAuthenticated}
          path="/callback"
          redirect="/"
        />
        <PublicRoute
          component={Authentication}
          isAuthenticated={isAuthenticated}
          path="/login"
          redirect="/"
        />
        <PublicRoute
          component={Authentication}
          isAuthenticated={isAuthenticated}
          path="/signup"
          redirect="/"
        />
        <PublicRoute
          component={Authentication}
          isAuthenticated={isAuthenticated}
          path="/forgot-password"
          redirect="/"
        />
        <PublicRoute
          component={Authentication}
          isAuthenticated={false}
          path="/reset-password"
          redirect="/"
        />
        <PublicRoute
          component={MfaLogin}
          isAuthenticated={isAuthenticated}
          path="/mfa-login"
          redirect="/"
        />
        <PublicRoute
          component={MfaEnroll}
          isAuthenticated={isAuthenticated}
          path="/mfa-enroll"
          redirect="/"
        />
        <PrivateRoute
          component={VerifyEmail}
          isAuthenticated={isAuthenticated}
          path="/verify-email"
          redirect="/login"
        />
        <PrivateRoute
          component={Setup}
          isAuthenticated={isAuthenticated}
          path="/setup"
          redirect="/login"
        />
        <PrivateRoute
          component={Application}
          isAuthenticated={isAuthenticated}
          path="/apps/:applicationId"
          redirect="/login"
        />
        <PrivateRoute
          component={Application}
          isAuthenticated={isAuthenticated}
          path="/team"
          redirect="/login"
        />
        <PrivateRoute
          component={Application}
          isAuthenticated={isAuthenticated}
          path="/user"
          redirect="/login"
        />
        <PrivateRoute
          component={Initialization}
          isAuthenticated={isAuthenticated}
          path="/apps"
          redirect="/login"
        />
        <PrivateRoute
          component={Initialization}
          isAuthenticated={isAuthenticated}
          path="/"
          redirect="/login"
        />
      </Switch>
    </div>
  );
};

export default App;

App.propTypes = {
  appId: string,
  appOrgId: string,
  session: shape({
    userContext: shape({
      firstName: string,
      lastName: string,
      email: string,
      dashboardUserId: string,
    }),
  }).isRequired,
  organization: shape({
    featureSetId: string,
  }),
};

App.defaultProps = {
  appId: null,
  appOrgId: null,
  organization: {},
};
