import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { Box } from '@mui/material';

import {
  Header,
  HeaderPill,
  Title,
  Subtitle,
  Addendum,
} from './styles';

const Card = ({
  header, headerPill, title, titleAddendum, titleError, subtitle, minWidth,
}) => {
  return (
    <Box sx={{ minWidth }}>
      <div>
        <Header variant="subtitle2" component="span">
          {header}
        </Header>
        {headerPill && <HeaderPill>{headerPill}</HeaderPill>}
      </div>
      <Title variant="h1" titleError={titleError}>
        {title}
        {titleAddendum && (
          <Addendum variant="subtitle2" component="span">
            {titleAddendum}
          </Addendum>
        )}
      </Title>
      {subtitle &&
        <Subtitle variant="subtitle2" subtitle={subtitle}>
          {subtitle}
        </Subtitle>
      }
    </Box>
  );
};

export default Card;

Card.propTypes = {
  header: string.isRequired,
  headerPill: string,
  title: oneOfType([number, string]).isRequired,
  titleAddendum: string,
  titleError: bool,
  subtitle: string,
  minWidth: string,
};

Card.defaultProps = {
  headerPill: '',
  titleAddendum: '',
  titleError: false,
  subtitle: '',
  minWidth: '250px',
};
