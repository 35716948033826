import React, { useEffect } from 'react';
import { bool, element, func, shape, string } from 'prop-types';
import { Typography } from '@mui/material';

import {
  useForm,
  Form,
  FormControlWrapper,
  ImageUpload,
  RadioGroup,
  TextInput,
} from '../../../../../../components/Form/useForm';
import staticText from '../../../../../../localization/Application/connect-config';
import { TabDescription, TabHeading } from '../../styles';
import { InlineRoute, Toast } from '../../../../../../components';
import { useDelay } from '../../../../../../hooks';

const Appearance = ({
  actions: {
    updateConnectConfigRequest,
    updateAppLogoRequest,
    deleteAppLogo,
    updateApplication,
    clearError,
  },
  appId,
  appName,
  connectConfig,
  previewSettings,
  isUpdatingAppLogo,
  handlePreviewSettingsUpdate,
  fetchingConnectConfigErrors,
  upgradeCta,
  upgradeMessage,
  renderErrorMessage,
}) => {
  const permission = 'write_connect_configuration';
  const { afterDelay: showLoading } = useDelay();

  const {
    appNameInput, themeInput, brandingInput, logoInput,
  } = staticText.appearance.inputs;

  const handleSettingsUpdate = (updatedFields) => {
    const {
      'app-name': name, theme, branding, 'logo-url': logoUrl,
    } = updatedFields;
    if (name) {
      updateApplication(appId, { name });
      Toast('Brand settings updated!', 'success');
    }
    if (theme || branding || logoUrl) {
      const configUpdates = {
        ...(theme && { theme }),
        ...(logoUrl && { logoUrl: previewSettings.selectedLogo }),
        ...(branding && { brandedHeader: branding === 'custom' }),
      };
      updateConnectConfigRequest(configUpdates);
    }
    if (logoUrl && connectConfig && connectConfig.logoUrl) {
      // Delete previously saved logo from S3 when new logo is saved to config.
      deleteAppLogo(connectConfig.logoUrl);
    }
  };

  const {
    formState, handleChange, handleBlur, handleSubmit, disableSubmit,
  } = useForm({
    formName: 'connect appearance settings form',
    inputs: [appNameInput, themeInput, brandingInput, logoInput],
    data: {
      [appNameInput.pathOnData]: appName,
      [brandingInput.pathOnData]: connectConfig && connectConfig.brandedHeader ? 'custom' : 'default',
      [themeInput.pathOnData]: (connectConfig && connectConfig.theme) || 'light',
      [logoInput.pathOnData]: (connectConfig && connectConfig.logoUrl) || '',
    },
    onSubmit: handleSettingsUpdate,
    onChange: data => handlePreviewSettingsUpdate(data),
    displayToast: false, // handled in update functions
  });

  useEffect(() => {
    // The logo is submitted to the backend as soon as the image is uploaded.
    // Instead of updating the form data immediately when the input changes,
    // we need to wait until we've received the logo's URL back from server,
    // then we can update the form data manually here to enable form submission.
    handleChange({ target: { name: 'logo-url', value: previewSettings.selectedLogo } });
  }, [previewSettings.selectedLogo]);

  useEffect(() => {
    return () => {
      // Delete last uploaded logo from S3 at time of unmount if it was not saved to the app config.
      if (previewSettings.selectedLogo && connectConfig.logoUrl !== previewSettings.selectedLogo) {
        deleteAppLogo(previewSettings.selectedLogo);
      }
    };
  }, []);

  return (
    <div>
      <TabHeading variant="h2">{staticText.appearance.heading}</TabHeading>
      {upgradeMessage}
      <TabDescription>{staticText.appearance.description}</TabDescription>
      <Form
        handleSubmit={handleSubmit}
        disableSubmit={disableSubmit ||
          (formState[brandingInput.pathOnData].value === 'custom' && !previewSettings.selectedLogo) ||
          isUpdatingAppLogo ||
          !!upgradeMessage
        }
        submitButtonLabel={staticText.publish}
        permission={permission}
        upgradeCta={upgradeCta}
      >
        <FormControlWrapper
          currentInputState={formState[appNameInput.pathOnData]}
          displayName={appNameInput.displayName}
          permission={permission}
        >
          <React.Fragment>
            {upgradeMessage &&
              <Typography variant="caption" marginTop={0.5}>
                <InlineRoute text={staticText.appearance.appNameCaption(appId)} />
              </Typography>
            }
            <TextInput
              {...appNameInput}
              handleBlur={handleBlur}
              handleChange={handleChange}
              currentInputState={formState[appNameInput.pathOnData]}
              debounceDelay={1000}
            />
          </React.Fragment>
        </FormControlWrapper>
        <FormControlWrapper
          currentInputState={formState[themeInput.pathOnData]}
          displayName={themeInput.displayName}
          permission={permission}
        >
          <RadioGroup
            {...themeInput}
            handleBlur={handleBlur}
            handleChange={handleChange}
            currentInputState={formState[themeInput.pathOnData]}
          />
        </FormControlWrapper>
        <FormControlWrapper
          currentInputState={formState[brandingInput.pathOnData]}
          displayName={brandingInput.displayName}
          permission={permission}
        >
          <React.Fragment>
            <RadioGroup
              {...brandingInput}
              handleBlur={handleBlur}
              handleChange={handleChange}
              currentInputState={formState[brandingInput.pathOnData]}
            />
            <ImageUpload
              {...staticText.appearance.logoUpload}
              previousImageUrl={previewSettings.selectedLogo || ''}
              disabled={formState[brandingInput.pathOnData].value !== 'custom' || !!upgradeCta}
              onUpload={updateAppLogoRequest}
              updating={isUpdatingAppLogo && showLoading}
              uploadError={fetchingConnectConfigErrors.updatingAppLogo}
              clearUploadError={clearError}
            />
          </React.Fragment>
        </FormControlWrapper>
        {fetchingConnectConfigErrors.updatingConnectConfig
          ? renderErrorMessage(fetchingConnectConfigErrors.updatingConnectConfig) : null}
      </Form>
    </div>
  );
};

export default Appearance;

Appearance.propTypes = {
  actions: shape({
    updateConnectConfigRequest: func,
    updateAppLogoRequest: func,
    deleteAppLogo: func,
    updateApplication: func,
    clearError: func,
  }).isRequired,
  appId: string.isRequired,
  appName: string.isRequired,
  connectConfig: shape({
    theme: string.isRequired,
    logoUrl: string,
    brandedHeader: bool.isRequired,
  }),
  previewSettings: shape({
    selectedLogo: string,
  }),
  handlePreviewSettingsUpdate: func.isRequired,
  isUpdatingAppLogo: bool.isRequired,
  fetchingConnectConfigErrors: shape({
    updatingAppLogo: string,
    updatingConnectConfig: string,
  }),
  upgradeCta: element,
  upgradeMessage: element,
  renderErrorMessage: func.isRequired,
};

Appearance.defaultProps = {
  connectConfig: {},
  previewSettings: {},
  fetchingConnectConfigErrors: {
    updatingAppLogo: '',
    updatingConnectConfig: '',
  },
  upgradeCta: null,
  upgradeMessage: null,
};
